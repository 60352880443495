<template>
    <div class="autocomplete" :ref="name">
        <v-overlay :value="isOpen" @click.native="OnOverlayClick" style="width: 100%;" opacity="0.05">
        </v-overlay>
        <v-text-field clearable
                      outlined
                      :dense="isInGrid"
                      :background-color="disabled ? 'grey lighten-3' : 'white'"
                      hide-details
                      :placeholder="disabled ? $t('IM_SelectionDisabled') : $t('IM_EnterMinCharactersSearch', { min: minLength})"
                      :label="label"
                      :id="elementId"
                      :class="{fielderror:error}"
                      class="my-1"
                      :disabled="disabled"
                      :error="error"
                      prepend-inner-icon="mdi-database-search"
                      :append-icon="approved == '0' ? 'mdi-timer-sand' : ''"
                      @input="onChange"
                      v-model="search"
                      @click="OnClick"
                      @keyup.down="onArrowDown"
                      @keyup.up="onArrowUp"
                      @keyup.enter="onEnter"
                      @keydown="keydown"
                      @focus="onFocus"
                      @click:clear="OnClear()" ref="input" />

        <div class="autocomplete-results-div" ref="list" :style="`top:${top}; bottom:${bottom}; left:${left}; right:${right}; min-width:300px; width:auto;`">
            <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results ma-0 pa-0" :style="`height:${height}px; min-width:300px; width:auto;`">
                <li v-if="isLoading" class="loading">Yükleniyor...</li>
                <li v-for="(result, i) in results"
                    :key="i"
                    @click="setResult(result)"
                    class="autocomplete-result"
                    :class="{ 'is-active': i === arrowCounter }">{{ result.text }}</li>
            </ul>
        </div>
    </div>
</template>

<style>
    .autocomplete {
        width: 100%;
    }


    .autocomplete-results {
        padding: 0;
        margin: 0;
        border: 1px solid #eeeeee;
        height: 120px;
        overflow: auto;
        width: 100%;
        background-color: white;
    }

    .autocomplete-results-div {
        position: fixed;
        background-color: white;
        width: 100%;
        z-index: 9000;
        border-radius: 5px;
    }

    .autocomplete-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
    }

        .autocomplete-result.is-active,
        .autocomplete-result:hover {
            background-color: lightgray;
        }

    .v-card__text {
        font-size: 16px;
    }

    .fielderror {
        border-color: red !important;
    }
</style>

<script>
    import { FormMixIn } from '@/MixIns/FormMixins';
    import Utilities from '@/Utilities.js'
    export default {
        name: "autocomplete",
        mixins: [FormMixIn],
        props: {
            itemtext: { type: [String, Array] },
            itemvalue: { type: String, default: "" },
            items: { type: Array, default: function () { return [] } },
            minLength: { type: Number, required: false, default: 3 },
            invalue: { type: String, required: false, default: "" },
            error: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
            label: { type: String },
            approved: { type: Boolean, default: true },
            isInGrid: { type: Boolean, default: false },
            height: { type: Number, default: 200 },
            params: Object,
            tableName: {
                type: String,
                default: function () {
                    return 'formdata'
                }
            },
            handleChangeAndSearchInComponent: { type: Boolean, default: false }
        },


        data: function () {
            return {
                isOpen: false,
                isDataLoaded: false,
                results: [],
                search: "",
                resultItem: "",
                isLoading: false,
                arrowCounter: 0,
                lastQuery: "",
                name: "test",
                lastValue: "",
                elementId: "",
                listVerticalMode: "top",
                listHorizontalMode: "left",
                bottom: "auto",
                right: "auto",
                update: 0,
                itemsInner: []
            };
        },

        methods: {

            onChange: function () {
                if (this.search == null) {
                    this.results = [];
                    this.isOpen = false;
                    return;
                }
                Utilities.log("AutoComplete onChange emit:" + this.search);
                var node = this.$refs[this.name];
                var focusedElement = document.activeElement;
                if (focusedElement == null) return;
                if (focusedElement != null) {
                    Utilities.log("Focused El:" + focusedElement.id + " serach id:" + this.elementId);
                    if (focusedElement.id != this.elementId) {
                        Utilities.log("focus element değil. returning")
                        return;
                    }
                }

                Utilities.log(
                    "search:" +
                    this.search +
                    " slen:" +
                    this.search.length +
                    " min:" +
                    this.minLength +
                    "ilen:" +
                    this.itemsInner.length +
                    " lo:" +
                    this.isLoading +
                    " sw:" +
                    _.startsWith(this.search, this.lastQuery) +
                    " lq:" +
                    this.lastQuery
                );
                if (this.search.length <= this.minLength) {
                    Utilities.log("clear results");
                    this.results = [];
                }
                // Eğer daha önce yüklenmediyse (this.items.length == 0 ) ve yükleme devam etmiyorsa ve min karakter sayısını geçersek yükle
                if (
                    this.search.length >= this.minLength
                ) {
                    Utilities.log("search loading");
                    this.lastQuery = this.search;
                    this.isLoading = true;
                    this.emitSearch(this.search);
                    //return;
                } else {
                    Utilities.log("filter");
                    this.filterResults();
                }
                this.isOpen = true;
            },
            emitSearch: function (search) {
                if (!this.handleChangeAndSearchInComponent) {
                    this.$emit("search", search);
                }
                else {
                    var tableName = this.tableName;
                    var id = 0;
                    var self = this;
                    var payload = {
                        search: search,
                        fieldName: this.params.name,
                        tableName: tableName,
                        id: id,
                        column: this.params,
                        success: function (response, req, payload2) {

                            self.itemsInner = payload2.value;
                        }
                    };
                    this.SearchInputChanged(payload, this.tableName)
                }
            },
            emitChange: function (event) {
                if (!this.handleChangeAndSearchInComponent) {
                    this.$emit("change", event);
                }
                else {
                    var searchText = this.params.searchText;
                    this.value = event.value ? event.value[searchText] : '';

                    var val = {
                        fieldName: this.params.name,
                        fieldValue: this.value,
                        column: this.params,
                        tableName: '',
                        id: 0,
                        selectedObject: event
                    };

                    this.UpdateFormField(val, this.tableName);
                    var payload = {};
                    payload.tableName = this.tableName;
                    payload.fieldName = this.params.name;
                    payload.value = [];
                    payload.selectedObject = val.selectedObject;
                    this.$store.commit('SetFieldAttributes', payload);

                    this.$emit("change", event);
                }
            },
            filterResults: function () {
                this.results = this.itemsInner;
                return;
            },
            setResult: function (result) {
                Utilities.log("setResult:", result, this.itemvalue); //+ JSON.stringify(result));
                var resultValue = this.itemvalue
                    .split(".")
                    .reduce((acc, part) => acc && acc[part], result.value);
                Utilities.log("Set Search field:", resultValue)
                this.search = resultValue;
                this.isOpen = false;
                this.arrowCounter = -1;
                Utilities.log("Emit:" + JSON.stringify(resultValue));
                this.emitChange(result);
                this.lastQuery = "";
                this.lastValue = this.search;
                this.results = [];
            },
            onArrowDown: function (evt) {
                if (this.arrowCounter < this.results.length) {
                    this.arrowCounter = this.arrowCounter + 1;
                }

            },
            onArrowUp: function () {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                }

            },
            keydown: function (event) {
                Utilities.log("Keydown:", event);
                if (event.key == "Tab") {
                    // Leave without selecting  a value
                    Utilities.log("onTab", event.target, this.isOpen);
                    if (this.isOpen) {
                        Utilities.log("search len:", this.search.length, "serach:", this.search, "last", this.lastValue)
                        if (this.search.length > 0 && this.lastValue != this.search) {
                            Utilities.log("set back");
                            this.search = this.lastValue;
                        } else if (this.search.length == 0) {
                            Utilities.log("set clear");
                            this.emitChange("");
                            this.lastValue = "";
                        }
                        this.isOpen = false;
                        this.arrowCounter = -1;
                        this.lastQuery = "";
                        this.results = [];
                    }
                }
                else Utilities.log("onkeydown do nothing");
            },
            onEnter: function () {
                Utilities.log("onEnter", this.arrowCounter);
                if (this.arrowCounter < 0) return;
                var result = this.results[this.arrowCounter];
                Utilities.log("onEnter selected item:" + JSON.stringify(result));

                var resultValue = this.itemvalue
                    .split(".")
                    .reduce((acc, part) => acc && acc[part], result);

                Utilities.log("onEnter sel val:" + JSON.stringify(resultValue));
                //this.search = resultValue;
                this.isOpen = false;
                this.arrowCounter = -1;
                //Utilities.log("Emit selected item:" + JSON.stringify(this.results[this.arrowCounter]));
                //this.$emit("input", this.results[this.arrowCounter]);
                //Utilities.log("selected item:" + result)
                this.emitChange(result);
                //this.lastValue = this.search;
                this.lastQuery = "";
                this.results = [];
            },

            onFocus: function (event) {
                if (this.search == null) {
                    this.results = [];
                    this.isOpen = false;
                    return;
                }
                if (this.search.length >= this.minLength) {
                    Utilities.log("search loading");
                    this.lastQuery = this.search;
                    this.isLoading = true;
                    this.emitSearch(this.search);
                    this.isOpen = true;
                    //return;
                }
            },
            handleClickOutside: function (event) {
                //Utilities.log("handleClickOutside last:" , this.lastValue , " cur:" , this.search , " Target:", event.target);

                if (!this.$el.contains(event.target)) {
                    //Utilities.log(" handleClickOutside outside");
                    if (this.isOpen) {
                        if (this.search == undefined) this.search = "";
                        //Utilities.log("clean");
                        if (this.search.length > 0 && this.lastValue != this.search) {
                            //Utilities.log(" handleClickOutside set back");
                            this.search = this.lastValue;
                        } else if (this.search.length == 0) {
                            this.emitChange("");
                            this.lastValue = "";
                        }
                    }
                    this.isOpen = false;
                    this.arrowCounter = -1;
                }
            },
            OnClick: function (event) {
                this.lastValue = this.search;

                //Utilities.log("onclick");
                var e = event;
                var node = event.currentTarget;
                var clientX = e.clientX - e.offsetX;
                var clientY = e.clientY - e.offsetY;


                var p = event.currentTarget.parentNode.childNodes[0];
                if (p.nodeName == "#text")
                    p = event.currentTarget.parentNode.childNodes[1];

                p.style.width = this.$el.clientWidth + "px";
            },
            OnClear: function (event) {
                this.emitChange("");
                this.lastValue = "";
            },
            OnOverlayClick: function (event) {
                this.isOpen = false;
            },
            GetTextboxPosition: function () {
                if (this.$refs.input != undefined) {
                    return this.$refs.input.$el.getBoundingClientRect();
                }
                else {
                    return 0;
                }
            },
            //updateAutocompletePosition: function () {
            //	var rect = this.GetTextboxPosition();
            //	this.left = rect.x;
            //	this.top = rect.y + 45;
            //},
            onScroll: function (e) {
                this.update++;
            }
        },
        computed:
        {
            top: function () {
                this.update;
                if (this.isOpen) {
                    var out = this.isOutOfViewport(this.$refs.list, this.GetTextboxPosition().bottom, this.height, this.GetTextboxPosition().left);
                    if (out.bottom) {
                        return (this.GetTextboxPosition().top - this.height) + "px";
                    }
                    else {
                        this.bottom = "auto";
                        return (this.GetTextboxPosition().bottom) + "px";
                    }
                }
                else {
                    return "auto";
                }
            },
            left: function () {
                this.update;
                if (this.isOpen) {
                    var out = this.isOutOfViewport(this.$refs.list, this.GetTextboxPosition().bottom, this.height, this.GetTextboxPosition().left);
                    if (out.right) {
                        this.right = parseInt((window.innerWidth || document.documentElement.clientWidth) - this.GetTextboxPosition().right) + "px";
                        return "auto";
                    }
                    else {
                        this.right = "auto";
                        return (this.GetTextboxPosition().left) + "px";
                    }
                }
                else {
                    return "auto";
                }
            },
            GetWidth: function () {
                return 0;
            }
        },

        watch: {
            outOfViewPort: {
                handler: function (val, oldValue) {
                    if (val.top) {
                        this.ListVerticalMode = "top";
                    }
                    if (val.bottom) {
                        this.ListVerticalMode = "bottom";
                    }
                    if (val.left) {
                        this.listHorizontalMode = "left";
                    }
                    if (val.right) {
                        this.listHorizontalMode = "right";
                    }
                    if (!out.any) {
                        this.ListVerticalMode = "top";
                        this.listHorizontalMode = "left";
                    }
                },
                deep: true
            },
            items: function (val, oldValue) {
                Utilities.log(" Autocomplete watch items", val);
                // actually compare them
                this.itemsInner = this.items;
            },
            itemsInner: function (val, oldValue) {
                this.results = val;
                if (val.length == 0) return;
                this.filterResults();
                this.isLoading = false;
            },
            invalue: function (nval, oval) {
                this.search = this.invalue || '';
                this.lastValue = this.search;
                Utilities.log("auto mounted invalue watch:", this.search, " invalue:", this.invalue);
            },
        },

        mounted: function () {
            //Utilities.log("auto mounted search:")
            document.addEventListener("click", this.handleClickOutside);
            this.elementId = Math.floor(Utilities.GetRandomNumber() * 100000000).toString();
            this.search = this.invalue || '';
            this.lastValue = this.search;
            //Utilities.log("auto mounted search:", this.search, " invalue:", this.invalue);
            window.addEventListener("scroll", this.onScroll, true);
        },
        destroyed: function () {
            document.removeEventListener("click", this.handleClickOutside);
            window.removeEventListener("scroll", this.onScroll, true);
        }
    };
</script>
