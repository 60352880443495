import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Utilities from '@/Utilities.js'
import store from '@/store';
Vue.use(VueI18n)

const loadedLanguages = [];


import tr from '@/assets/locales/tr.js'
import en from '@/assets/locales/en.js'

const messages = {
    tr: tr,
    en: en
}

var currentLang = Utilities.GetCurrentLanguage();

//loadLanguageAsync(currentLang);

// Create VueI18n instance with options
export const i18n = new VueI18n({
    locale: currentLang, // set locale
    fallbackLocale: 'tr',
    messages, // set locale messages
})


function setI18nLanguage(lang) {
    i18n.locale = lang;
    document.querySelector('html').setAttribute('lang', lang);
    Utilities.SetCurrentLanguage(lang);
    return lang;
}

export function loadLanguageAsync(lang) {

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }
    var payload = {
        action: 'GetI18nData',
        formdata: { formInputs: { lang: lang } }    
    };
    return store.dispatch('AccountAction', payload).then(response => {
        var allMessages = response.data.result;
        allMessages.$vuetify = tr.$vuetify;
        if (lang == 'en') {
            allMessages.$vuetify = en.$vuetify;
        }
        i18n.setLocaleMessage(lang, allMessages)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang);
    });
}