<template>
    <div class="dropdown w-100"
         @click="toggleList"
         :class="{ expanded: isExpanded }">
        <div class="dropdown-label-container w-100">
            <div class="dropdown-label" style="border: 2px #808080 solid;
                border-radius: 5px;
                height: 43px;
                width: 80%;
                vertical-align: middle;
                float: left">
                <span class="text" style="padding-right: 20px; padding-left:10px;">
                    {{ placeholder  }}
                </span>
                <em class="angle-down" :class="{ toggled: isExpanded }"></em>
            </div>
        </div>

        <div v-expand="isExpanded" class="options expand" v-if="isExpanded"
             style="border: 2px #808080 solid;
             border-radius: 5px;
             height: 100%;
             width: 80%;
             vertical-align: middle;
             background-color:white;
             z-index:999;
             float: left">
            <div v-for="option in configOptions"
                 class="option"
                 @click="setCurrentSelectedOption(option);">
                {{ option.text }}
            </div>
        </div>
    </div>
</template>
<style scoped>
    .dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        width: 110px;
        position: relative;
        border: 1px solid transparent;
        border-radius: 3px;
        cursor: pointer;
        color: #202020;
        background: white;
        user-select: none;
                    .dropdown-label-container

                {
                    display: flex;
                    width: 100%;
                    .dropdown-label

                {
                    display: flex;
                    justify-content: space-between;
                    flex: 1 1 auto;
                    align-items: center;
                    height: 50px;
                    padding: 3px;
                    .text

                {
                }

    .angle-down {
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid #808080;
        color: black;
        border-width: 0 2px 2px 0;
        padding: 4px;
        width: 1px;
        height: 1px;
        margin: -3px 5px 0 0;
        border-color: var(--dropdown-default-text-color);
        transform: rotate(45deg);
        transition: all 0.7s;
    }

    .toggled {
        margin-bottom: -12px;
        transform: rotate(-135deg);
        transition: all 0.7s;
    }

    }
    }

    .options {
        width: 100%;
        background-color:white;
        .option

    {
        display: flex;
        align-items: center;
        padding: 3px;
        margin-right:20px;
        margin-left:10px;
        padding-right:20px;
        height: 35px;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 18px;
    }

    .option:hover {
        background: lightgrey;
    }

    .option:last-child {
        border-radius: 0 0 5 5;
    }

        .option:last-child:hover {
            border-radius: 0 0 5 5;
        }

    }
    }

    .dropdown.expanded {
        background: white;
    }


    .expand {
        overflow: hidden;
        &[aria-expanded="false"]

    {
        height: 100px !important;
    }

    }


    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    }
</style>
<script>
    import Utilities from '@/Utilities.js'
    export default {
        name: "dropdown",
        data()
        {
            return {
                isBottomSectionToggled: false,
                optionsHeight: 200,
                optionHeight: 35,
                width: 100,
                configOptions: [],
                currentText: '',
                backgroundColor: "#cde4f5",
                backgroundExpandedColor: "#fff",
                hoverBackgroundColor: "#0084d4",
                border: "1px solid  #232b35",
                borderRadius: 0,
                textColor: "#fff",
                placeholder: 'Seçiniz',
                isExpanded: false
            };
        },
        components: {},
        props: {
            items: Array,
            value: [String,Number],
        }
        ,
        computed: {
            computedStyles: function ()
            {
                return [
                    //{ "--options-height": this.optionsHeight + "px" },
                    //{ "--options-height-neg": "-" + this.optionsHeight + "px" },
                    //{ "--option-height": "35px" },
                    //{ "--main-el-border-radius": this.borderRadius },
                    //{ "--dropdown-width": this.width + "px" },
                    //{ "--dropdown-background-color": this.backgroundColor },
                    //{ "--dropdown-expanded-color": this.backgroundExpandedColor },
                    //{ "--dropdown-border": this.border },
                    //{ "--dropdown-hover-background-color": this.hoverBackgroundColor },
                    //{ "--dropdown-default-text-color": this.textColor }
                ];
            }
        },
        directives: {
            expand: {
                inserted: function (el, binding)
                {
                    if (binding.value !== null)
                    {
                        function calcHeight()
                        {
                            //    const currentState = el.getAttribute("aria-expanded");
                            //    el.classList.add("u-no-transition");
                            //    el.removeAttribute("aria-expanded");
                            //    el.style.height = null;
                            //    el.style.height = el.clientHeight + "px";
                            //    el.setAttribute("aria-expanded", currentState);

                            //    setTimeout(function ()
                            //    {
                            //        el.classList.remove("u-no-transition");
                            //    });
                            //}
                            //el.classList.add("expand");
                            //el.setAttribute("aria-expanded", binding.value ? "true" : "false");
                            //calcHeight();
                            //window.addEventListener("resize", calcHeight);
                        }
                    }
                },
                update: function (el, binding)
                {
                    if (el.style.height && binding.value !== null)
                    {
                        el.setAttribute("aria-expanded", binding.value ? "true" : "false");
                    }
                }
            }
        },
        methods: {
            toggleList()
            {
                this.isExpanded = !this.isExpanded;
            },
            setCurrentSelectedOption(option)
            {
                this.$emit("change", option);
            },
            setConfigData()
            {
                //this.configOptions = this.config.options;
                //this.width = this.config.width;
                //this.placeholder = this.config.placeholder;
                //if (this.config.backgroundColor) {
                //  this.backgroundColor = this.config.backgroundColor;
                //}
                //if (this.config.backgroundExpandedColor) {
                //  this.backgroundExpandedColor = this.config.backgroundExpandedColor;
                //}
                //if (this.config.border) {
                //  this.border = this.config.border;
                //}
                //if (this.config.hoverBackgroundColor) {
                //  this.hoverBackgroundColor = this.config.hoverBackgroundColor;
                //}
                //if (this.config.textColor) {
                //  this.textColor = this.config.textColor;
                //}
                //if (this.config.borderRadius) {
                //  this.borderRadius = this.config.borderRadius;
                //}
            },
            setOptionsHeight()
            {
                // this.optionsHeight = this.optionHeight * this.configOptions.length;
            }
        },

        beforeUdate()
        {
            // this.setOptionsHeight();
        },
        mounted()
        {
            this.configOptions = this.items;
            this.currentValue = this.value;
            var self = this;
            var item = this.items.find(function (ext)
            {
                return ext.value == self.value;
            });
            if (item) this.placeholder = item.text
            else this.placeholder = this.$root.$i18n.t('OP_PleaseSelect');
        },
        watch:
        {
            value: function (val, oldValue)
            {
                //Utilities.log("watch value in dropdown");
                var self = this;
                var item = this.items.find(function (ext)
                {
                    return ext.value == self.value;
                });
                if (item) this.placeholder = item.text
                else this.placeholder = this.$root.$i18n.t('OP_PleaseSelect');
            },
            items: function (val, oldValue)
            {
                //Utilities.log("watch items in dropdown");
                this.configOptions = this.items;
            },
        }
    };
</script>
