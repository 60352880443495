<template>
    <div>
        <v-dialog v-model="showDialog" width="390">
            <v-card class="mx-auto pt-7 pb-2">
                <alertIcon :icon="vicon" />
                <v-card-title class="mb-0">
                    <p class="mx-auto headline pb-0 mb-0">{{ infoBody }}</p>
                </v-card-title>
                <v-card-text class="text-center" v-html="messageBody">
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="messageType.indexOf('C') >= 0" text :color="color" class="white--text font-weight-bolder px-5" @click="CloseDialogWithResult('approve')">
                        {{$t("AL_Yes")}}
                    </v-btn>
                    <v-btn v-if="messageType.indexOf('C') >= 0" text style=" color: #a4a2a2" class="font-weight-bolder px-5" @click="CloseDialogWithResult('reject')">
                        {{$t("AL_Reject")}}
                    </v-btn>
                    <v-btn v-else text :color="color" class="white--text font-weight-bolder px-5" @click="CloseDialog()">
                        {{$t("AL_Okey")}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="notification" :timeout="3000" :multi-line="true" :color="color" class="primary--text text--lighten-5" top right>
            <v-list v-if="messageType == 'E' && errors && errors.length > 0" dense id="idlistofnotif" style="max-height: 200px" class="ma-0 pa-0 overflow-y-auto" color="transparent">
                <v-list-item v-for="err in errors" :key="err.index">
                    <v-list-item-content>
                        <span class="notification" v-html="err"></span>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-list v-else-if="messageBody" dense class="ma-0 pa-0" color="transparent">
                <v-list-item>
                    <v-list-item-content>
                        {{ messageBody }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:action="{ attrs }">
                <v-btn color="primary--text ligthen-4"
                       icon
                       v-bind="attrs"
                       @click="notification = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<style>
    #idlistofnotif::-webkit-scrollbar {
        width: 10px;
    }

    #idlistofnotif::-webkit-scrollbar-track {
        background: #fdfdfd20;
        border-radius: 10px;
    }

    #idlistofnotif::-webkit-scrollbar-thumb {
        background: #fdfdfd80;
        border-radius: 10px;
    }

        #idlistofnotif::-webkit-scrollbar-thumb:hover {
            background: #ffffffA0;
        }
</style>
<script>
    import axios from 'axios';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import Utilities from '@/Utilities.js'

    import Vue from 'vue';
    import alertIcon from './alertIcon';
    Vue.component('alertIcon', alertIcon);

    export default {
        name: 'showdialog',
        props: {
            message: Object
        },

        data() {
            return {
                messageType: '',
                messageBody: '',
                infoBody: '',
                vicon: 'error',
                color: 'red',
                messageHeader: '',
                showDialog: false,
                notification: false,
                errors: [],
                timerId: null,
                OnCloseDialog: undefined,
                OnApprove: undefined
            };
        },
        mixins: [FormMixIn],
        mounted() {
            this.$root.$on('setUpDialog', (data) => {
                Utilities.log("setUpDialog ===> ", data);

                if (data.messageType != null)
                    this.messageType = data.messageType;
                else
                    this.messageType = '';

                if (data.errors != null && typeof (data.errors) == 'object' && data.errors.length > 0)
                {
                    this.messageBody = data.errors.join('\n<br>');
                }
                else
                {
                    if (data.errors == null)
                        this.messageBody = data.errors;
                    else
                        this.messageBody = '';
                }
                this.infoBody = data.infoBody;
                this.ChangeAlertDisplay(data.messageType);
                this.showDialog = data.viewType == 'dialog';
                this.notification = data.viewType == 'notification';
                this.OnApprove = data.OnApprove;
                this.errors = data.errors;
                this.OnCloseDialog = data.OnCloseDialog;
            });
        },

        methods: {
            CloseDialog() {
                this.ClearFieldErrors();
                this.errors = [];
                Utilities.log('Close message dialog');
                if (this.messageType == 'I') {
                    // Yeniden view yükle

                    var viewname = this.GetViewName;
                    this.$store.commit('UpdateViewName', { viewName: '' });
                    setTimeout(() => {
                        Utilities.log(' Timeout UpdateViewName', viewname);
                        this.$store.commit('SetWhatToShow', { show: 'view' });
                        this.$store.commit('UpdateViewName', { viewName: viewname });
                    }, 1);
                }

                if (this.OnCloseDialog != undefined) {
                    this.OnCloseDialog();
                }

                this.showDialog = false;
                this.timerId = null;
            },
            CloseDialogWithResult(message) {
                this.ClearFieldErrors();
                this.errors = [];
                Utilities.log('Close confirm dialog with result', message);

                if (this.OnCloseDialog != undefined) {
                    this.OnCloseDialog();
                }

                if (message == 'approve') {
                    this.OnApprove();
                } else {
                    if (this.messageType == 'C') {
                        this.notification = true;
                        this.messageBody = this.$root.$i18n.t('DM_ConfirmationCanceled');
                    }
                }

                this.showDialog = false;
                this.timerId = null;
            },
            ChangeAlertDisplay(type) {
                let _vicon = 'error';
                let _color = 'red';
                let _messageHeader = this.$root.$i18n.t('DM_Error');

                switch (type) {
                    case "B":
                        _vicon = 'success';
                        _color = 'green';
                        _messageHeader = this.$root.$i18n.t('DM_Success');
                        break;
                    case "CB":
                        _vicon = 'success';
                        _color = 'green';
                        _messageHeader = this.$root.$i18n.t('DM_Success');
                        break;
                    case "C":
                        _vicon = 'warning';
                        _color = 'orange';
                        _messageHeader = this.$root.$i18n.t('DM_Warning');
                        break;
                    case "I":
                        _vicon = 'info';
                        _color = 'blue';
                        _messageHeader = this.$root.$i18n.t('DM_Info');
                        break;
                    case "V":
                    case "W":
                        _vicon = 'warning';
                        _color = 'orange';
                        _messageHeader = this.$root.$i18n.t('DM_Warning');
                        break;
                    default:
                        _vicon = 'error';
                        _color = 'red';
                        _messageHeader = this.$root.$i18n.t('DM_Error');
                        break;
                }

                this.vicon = _vicon;
                this.color = _color;
                this.messageHeader = _messageHeader;
            }
        },
        watch: {
            showDialog: function (newValue, oldValue) {
                console.log('New: ', newValue, '  Old', oldValue);
                Utilities.log('New: ', newValue, '  Old', oldValue);
            }
        }
    };
</script>
