
<template>
    <div v-if="!Hide" :class="GetAlignment(column)">
        <span v-if="!Editable" :class="GetAlignment(column)">


            <upload v-if="column.type == 'file'"
                    @change="UpdateValueFile($event, column)"
                    @changeAttribute="ChangeAttributeFile($event)"
                    :editable="Editable"
                    :params="column"
                    v-model="value"
                    :invalue="value"
                    :class="{ fielderror: Error }"
                    class="my-1"
                    :label="$t(column.label)"
                    :readonly="Readonly"
                    :approved="true"
                    :id="tableName + '_' + col + '_' + row"
                    :isInGrid="true"
                    :disabled="Disabled"></upload>

            <span v-else-if="column.type != 'button'" :class="GetAlignment(column)">
                {{ value | FormatColumn(column,$t('ST_Undefined')) }}
            </span>
        </span>
        <v-btn v-if="column.type == 'button'"
               depressed block color="primary white--text"
               @click="ButtonClicked(column)"
               :value="$t(column.label)"
               :error="Error"
               :readonly="Readonly"
               :disabled="Disabled"
               :rows="column.height">{{ $t(column.label)}}</v-btn>

        <v-text-field v-if="column.type == 'text' && Editable"
                      outlined
                      dense
                      hide-details
                      :placeholder="PlaceHolder"
                      @keyup="RowUpDown($event)"
                      @change="UpdateValue($event, column)"
                      v-model="value"
                      :error="Error"
                      :readonly="Readonly"
                      :disabled="Disabled"
                      :id="tableName + '_' + col + '_' + row"
                      color="accent"></v-text-field>

        <v-textarea v-if="column.type == 'textarea' && Editable"
                    @change="UpdateValue($event, column)"
                    v-model="value"
                    :placeholder="$t(column.label)"
                    auto-grow
                    :error="Error"
                    :readonly="Readonly"
                    :disabled="Disabled"
                    :id="tableName + '_' + col + '_' + row"
                    :rows="column.height"></v-textarea>


        <v-text-field v-if="(column.type == 'number' || column.type == 'int') && Editable"
                      outlined
                      dense
                      hide-details
                      type="number"
                      :placeholder="PlaceHolder"
                      @keyup="RowUpDown($event)"
                      @change="UpdateValue($event, column)"
                      v-model="value"
                      :error="Error"
                      :readonly="Readonly"
                      :disabled="Disabled"
                      :id="tableName + '_' + col + '_' + row"
                      color="accent"></v-text-field>

        <v-checkbox v-if="column.type == 'checkbox' && Editable"
                    v-model="value"
                    hide-details
                    class="shrink mr-2 mt-0"
                    :placeholder="$t(column.label)"
                    :error="Error"
                    :readonly="Readonly"
                    :disabled="Disabled"
                    :id="tableName + '_' + col + '_' + row"
                    @change="UpdateCheckbox($event, column)"></v-checkbox>

        <span v-if="column.type == 'link'"
              @click="GetLink(column)"
              style="color:blue; cursor: pointer;">{{ value }}</span>

        <v-select v-if="column.type == 'select' && Editable"
                  outlined
                  dense
                  background-color="white"
                  hide-details
                  :placeholder="$t(column.label)"
                  :id="tableName + '_' + col + '_' + row"
                  v-model="value"
                  :invalue="value"
                  :items="column.options"
                  @change="UpdateSelect($event, column)"
                  :error="Error"
                  :readonly="Readonly"
                  :disabled="Disabled"
                  :multiple="column.Multiple"
                  color="accent">
        </v-select>

        <upload v-if="column.type == 'file' && Editable"
                @change="UpdateValueFile($event, column)"
                @changeAttribute="ChangeAttributeFile($event)"
                :editable="Editable"
                :params="column"
                v-model="value"
                :invalue="value"
                :class="{ fielderror: Error }"
                class="my-1"
                :label="$t(column.label)"
                :readonly="Readonly"
                :error="Error"
                :approved="true"
                :id="tableName + '_' + col + '_' + row"
                :isInGrid="true"
                :disabled="Disabled"></upload>
        <v-icon v-if="column.type == 'iconic'">{{ value }}</v-icon>

        <v-menu v-if="column.type == 'date' && Editable"
                v-model="dateopen"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field dense outlined
                              :background-color="Disabled ? 'grey lighten-3' : 'white'"
                              hide-details
                              append-icon="mdi-calendar-month"
                              :value="value | FormatDateTR"
                              @click="dateopen = !dateopen"
                              :disabled="Disabled"
                              readonly
                              clearable
                              :id="tableName + '_' + col + '_' + row"
                              @click:clear="ClearDate($event,column)"
                              :class="{ fielderror: Error, fieldDisabled: Disabled }"
                              class="my-1"
                              :placeholder="$t(column.label)"
                              color="primary"></v-text-field>
            </template>
            <v-date-picker v-if="dateopen"
                           v-model="value"
                           :disabled="Disabled"
                           @change="UpdateValue($event, column)"
                           @input="menu2 = false"
                           color="primary lighten-2"
                           header-color="primary"
                           locale="tr"></v-date-picker>
        </v-menu>

        <autocomplete v-if="column.type == 'auto' && Editable"
                      v-model="value"
                      :invalue="value"
                      :itemvalue="column.searchText"
                      :minLength="column.minLength"
                      dense
                      :items="GetAttrItems(attributes)"
                      :loading="isLoading"
                      :search-input.sync="search"
                      item-text="Description"
                      item-value="API"
                      return-object
                      @search="SearchInputChangedDebounced($event, column.name)"
                      @change="UpdateValue($event, column)"
                      @changeAttribute="ChangeAttribute($event)"
                      :id="tableName + '_' + col + '_' + row"
                      :isInGrid="true"
                      :error="Error"
                      :readonly="Readonly"
                      :disabled="Disabled">

        </autocomplete>
    </div>
</template>

<style>
    input[type="number"] {
        -moz-appearance: textfield;
        text-align: right !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
</style>

<script>
    import Vue from 'vue';
    import numeral from 'numeral';
    import moment from 'moment';
    import Utilities from '@/Utilities.js'

    export default {
        name: 'gridfield',
        props: {
            type: String,
            column: Object,
            invalue: [String, Number, Date, Object, Boolean],
            state: String,
            attributes: Object,
            rowupdown: { type: Boolean, default: false },
            tableName: { type: String, default: 'Table' },
            row: { type: Number, default: 0 },
            col: { type: String, default: '' },
        },
        data() {
            return {
                items: [],
                isLoading: false,
                model: '',
                date: null,
                menu: false,
                search: '',
                value: null,
                datestring: '',
                dateopen: false
            };
        },
        computed: {
            Editable: function () {
                var label = this.column.label;
                var edit = this.column.edit || '';
                var state = this.state || '';
                if (edit == '' || state == '') return false;
                return edit.indexOf(this.state) != -1;
            },
            Readonly: function () {
                return this.attributes ? this.attributes.readonly || false : false;
            },

            Disabled: function () {
                return this.attributes ? this.attributes.disabled || false : false;
            },
            Error: function () {
                //Utilities.log("Error :", this.column, this.attributes);
                return this.attributes ? this.attributes.error || false : false;
                //return false;
            },
            Hide: function () {
                var hide = this.attributes ? this.attributes.hide || '' : '';
                var hidden = hide.indexOf(this.state) != -1;
                return hidden;
            },
            PlaceHolder: function () {
                if (this.column.mask == 'phone') {
                    return '(___) ___ __ __';
                }
                else if (this.column.mask == 'email') {
                    return 'name@companyname.com';
                }
                else {
                    return this.$root.$i18n.t(this.column.label);
                }
            },
        },
        methods: {
            GetAttrItems: function (attributes)
            {
                if (attributes == undefined)
                {
                    return [];
                }

                if (attributes.items == undefined)
                {
                    return [];
                }

                return attributes.items;
            },
            Dump(what, props) {
                Utilities.log('Dump Props of:', what, props);
            },
            RowUpDown: function (event) {
                Utilities.log("---------++++++++^^^^^^^^^^^^^^^^Event grid field", event);
                var key = event.which;
                var dir = '';
                if (key == 38) dir = 'up';
                else if (key == 40) dir = 'down';
                if (dir == '') return;
                var id = event.target.id;
                var payload = {};
                payload.event = event;
                payload.id = id;
                payload.dir = dir;
                this.$emit('rowupdown', payload);

            },
            setdate: function (val, name) {
                //Utilities.log("setdate val:" + val + " name:" + name);
                this.dateopen = false;
            },
            UpdateValue: function (event, params) {
                Utilities.log('UpdateValue UpdateValue Payload:', event, params);
                Utilities.log('val:', this.value);
                var val = event; // this.value; // event.target.value;
                let type = this.column.type;
                if (type == 'auto') val = event.text;
                if (type == 'number' || type == 'int') val = this.value;
                // var fieldname = params.name;
                if (this.column.type == 'checkbox') {
                    if (this.value) val = this.column.truevalue || '1';
                    else val = '';
                }
                if (this.column.type == 'text' && this.column.mask && this.column.mask == 'phone') { val = Utilities.CleanPhoneNumber(val); }

                var payload = {
                    fieldValue: val,
                    selectedObject: event,
                    column: this.column,
                    item: this.item,
                };

                if (params.type == 'date') this.dateopen = false;
                Utilities.log('GridField UpdateValue Payload:', payload);
                this.$emit('change', payload);
            },

            //UpdateValueFile: function (event) {
            //    Utilities.log('UpdateValueFile', event);
            //    this.$emit('change', event);
            //},

            UpdateValueFile: function (event, column) {

                var payload = {
                    fieldValue: event.fieldValue,
                    selectedObject: { ...event, column },
                    column: this.column,
                    item: this.item,
                };

                this.$emit('change', payload);
            },

            ChangeAttribute: function (event, fieldName) {
                Utilities.log("Field Emit changeAttribute", event)
                this.$emit("changeAttribute", { ...event, fieldName });
            },

            ChangeAttributeFile: function (e) {
                Utilities.log("Field Emit changeAttribute", e)
                this.$emit("changeAttribute", e);
            },
            UpdateSelect: function (event, column) {
                var value = event;
                if (column.Multiple === true) {
                    var seperateCharacter = 'é';
                    if (column.CustomSeparateCharacter != undefined) {
                        seperateCharacter = column.CustomSeparateCharacter;
                    }
                    value = event.join(seperateCharacter);
                }

                var payload = {
                    //fieldName: column.name,
                    fieldValue: value,
                    selectedObject: value,
                    column: column,
                };

                this.$emit('change', payload);
            },

            UpdateCheckbox: function (event, column) {
                var fieldValue = 0;

                if (
                    typeof this.column.options != 'undefined' &&
                    this.column.options != ''
                ) {
                    fieldValue = this.column.options[event ? 0 : 1].value;
                } else if (fieldValue == '0' || fieldValue == '1') {
                    fieldValue = event;
                } else {
                    //alert('hatalı checkbox tanımı:' + column.label);
                }
                if (fieldValue) {
                    fieldValue = this.column.truevalue || "1";
                }
                else {
                    fieldValue = "0";
                }
                //var fieldName = column.name;
                //alert("Box state:" + fieldValue);
                var payload = {
                    fieldValue: fieldValue,
                    selectedObject: event,
                    column: this.column,
                    item: this.item
                };
                Utilities.log('update checkbox item:', payload);
                this.$emit('change', payload);
            },

            ButtonClicked: function (col) {
                Utilities.log('Button cliecked:', col);
                this.$emit('click', col);
            },

            SearchInputChangedDebounced: function (val, column) {
                //Utilities.log("Grid SearchInputChangedDebounced:", val);
                // _.debounce(function(payload) {
                //if (typeof event == "undefined" || val == self.value) return;
                var payload = {};
                payload.search = val;
                payload.tableName = column.name;
                //Utilities.log("Emit autocomplete search payload:", payload);
                this.$emit('SearchInputChanged', payload);
                // }, 500)
            },
            GetLink: function (column) {
                Utilities.log('Link clicked:', column);
                var url = column.link;
                var linkvalue = this.value;
                if (typeof column.linkvalue != 'undefined' && column.linkvalue != '') {
                    linkvalue = this.GetFormField(linkvalue);
                }
                if (typeof url == 'undefined' || url == '') {
                    this.$emit('linkclicked', column);
                } else {
                    window.open(url + linkvalue, '_blank');
                }
            },
            GetAlignment(column) {
                return 'text-' + column.align;
            },
            GetColumnWidth(column) {
                var result = "";
                if (typeof column.width != "undefined") result = "min-width:" + column.width + "px;";
                Utilities.log("GetColumnWidth:", result, column)
                return result;
            },
            queryForKeywords: function (event) {
                //if (
                //	!(
                //		(event.keyCode >= 48 && event.keyCode <= 57) ||
                //		(event.keyCode >= 96 && event.keyCode <= 105)
                //	) &&
                //	event.keyCode != 8
                //) {
                //	return event.preventDefault();
                //}
                return true;
            }
        },
        created() {
            //Utilities.log('Grid field created');
        },
        mounted() {
            //Utilities.log('Grid field mounted', this.column, this.type, "in:", this.invalue);
            //this.state = this.$store.getters.GetFormState;
            if (this.column.type == 'checkbox') {
                this.value = this.invalue == (this.column.truevalue || '1');
            } else if (this.column.type == 'select') {
                var selectedValue = this.invalue;
                if (selectedValue != null && selectedValue != undefined && this.column.Multiple === true) {

                    var seperateCharacter = 'é';
                    if (this.column.CustomSeparateCharacter != undefined) {
                        seperateCharacter = this.column.CustomSeparateCharacter;
                    }
                    try {
                        selectedValue = selectedValue.split(seperateCharacter);
                    }
                    catch
                    {
                        selectedValue = "";
                    }
                }
                this.value = selectedValue;
            } else if (this.column.type == 'date') {
                this.value = this.invalue == null ? null : this.invalue.split('T')[0]; //this.invalue;
                if (!this.value || this.value == null) return '';

                var d = moment(this.value.split('T')[0]);
                if (typeof this.column.format == 'undefined')
                    this.column.format = 'DD/MM/YYYY';
                //Utilities.log('date convert:', value, column.format, temp, d)
                this.datestring = moment(this.value.split('T')[0])
                    .format(this.column.format)
                    .split('T')[0];
            }
            else if (this.column.type == 'int') {
                this.value = this.invalue == null ? '0' : this.invalue.toString();
            } else {
                this.value = this.invalue;
                this.currency = this.doviz || 'TL';
                this.datestring = this.value;
            }
            //Utilities.log("Mounted Grid Field:",this.column.name, this.column, this.invalue, this.value);
        },
        watch: {
            invalue: function (val, old) {
                // Utilities.log(
                // 	'Watch GridField:' + this.column.name + ' Val:' + val + ' Old:' + old
                // );
                if (this.column.type == 'checkbox') {
                    this.value = this.invalue == (this.column.truevalue || '1');
                } else if (this.column.type == 'date') {
                    this.value = val;
                    if (!this.value || this.value == null) return '';

                    var d = moment(this.value.split('T')[0]);
                    if (typeof this.column.format == 'undefined')
                        this.column.format = 'DD/MM/YYYY';
                    //Utilities.log('date convert:', value, column.format, temp, d)
                    this.datestring = moment(this.value.split('T')[0])
                        .format(this.column.format)
                        .split('T')[0];
                }
                else if (this.column.type == 'int') {
                    this.value = val.toString();
                }
                else if (this.column.type == 'select') {
                    var selectedValue = val;
                    if (selectedValue != null && selectedValue != undefined && this.column.Multiple === true) {
                        var seperateCharacter = 'é';
                        if (this.column.CustomSeparateCharacter != undefined) {
                            seperateCharacter = this.column.CustomSeparateCharacter;
                        }
                        selectedValue = selectedValue.split(seperateCharacter);
                    }
                    this.value = selectedValue;
                }
                else {
                    this.value = val;
                    this.datestring = val;
                }
            },
            state: function (val, old) {
                //Utilities.log('Watch: State  Val:' + val + ' Old:' + old);
                this.state = val;
            },
            value: function (n, old) {
                //Utilities.log("Watch: value  Val:" + n + " Old:" + old);
                if (this.column.type == 'text' && this.column.mask && this.column.mask == 'phone') {
                    var formated = Utilities.FormatPhoneNumber(n);
                    if (n != formated) this.value = formated;
                }
            },
            attributes: function (n, o) {
                //Utilities.log(" Watch Attributes in GridField", n, o);
            }
        },
        filters: {
            FormatDateTR: function (value) {
                if (!value) return '';
                var parts = value.split('-');
                return parts[2] + '/' + parts[1] + '/' + parts[0];
            },
            FormatColumn: function (value, column, undefinedtext) {
                // Utilities.log("in filter", column, value)
                var result = value;
                var type = column.type;
                var format = column.format ? column.format : '';

                switch (type) {
                    case 'text':
                    case 'textarea':
                    case 'auto':
                        break;
                    case 'number':
                    case 'money':
                        result = numeral(value).format(format != '' ? format : '0,0.[0000000]');
                        break;

                    case 'date':
                        if (value === null || value === '') {
                            result = '-';
                        }
                        else {
                            result = (moment(value).format(format != '' ? format : 'DD/MM/YYYY'));
                        }
                        break;
                    case 'datetime':
                        if (value === null || value === '') {
                            result = '-';
                        }
                        else {
                            result = (moment(value).format(format != '' ? format : 'DD/MM/YYYY HH:mm'));
                        }
                        break;
                    case 'select':
                        // Utilities.log("Grid Select value0:", column);

                        var item = '';

                        if (column.Multiple === true) {
                            if (value != null && value != undefined && value != "") {
                                var seperateCharacter = 'é';
                                if (column.CustomSeparateCharacter != undefined) {
                                    seperateCharacter = column.CustomSeparateCharacter;
                                }
                                value = value.split(seperateCharacter);

                                item = column.options.filter(function (ext) {
                                    return value.filter(function (val) {
                                        return val == ext.value;
                                    }).length > 0;
                                });
                                if (item.length > 0) {
                                    item = item.map(function (elem) {
                                        return elem.text;
                                    }).join(", ");
                                }
                            }
                            item = item ? item : undefinedtext;
                        }
                        else {
                            item = column.options.find(function (ext) {
                                return ext.value == value;
                            });
                            item = item ? item.text : undefinedtext;
                        }

                        result = item;
                        //Utilities.log("Grid Select value", value, " Item:", item, " Column:", column);
                        break;

                    case 'checkbox':
                        var optionList = column.options.split('|');
                        if (optionList.length == 2)
                            result = value ? optionList[0] : optionList[1];
                        //return result;
                        break;
                    case 'file':
                        if (!value || value == null) {
                            result = '';
                            break;
                        }
                        var parts = value.split('__');
                        if (parts.length >= 2) result = value.replace(parts[0] + '__', '');
                        else result = value;
                        //result = '';
                        break;
                    default:
                        break;
                }
                //Utilities.log("filter result  f:" + column.label + " type:" + type + " val:" + value + " formated:" + result);
                return result;
            }
        }
    };
</script>
