import { mapGetters } from "vuex";
import XLSX from "xlsx";
const make_cols = refstr => Array(XLSX.utils.decode_range(refstr).e.c + 1).fill(0).map((x, i) => ({ name: XLSX.utils.encode_col(i), key: i }));

var timerId = 0;
import Utilities from '@/Utilities.js'
export const FormMixIn = {
    data: function () {
        return {
            globals: {},
            actionBarWait: false,
        }
    },
    computed: {
        ...mapGetters([
            "GetFormColumns",
            "GetFormAttributes",
            "GetDynamicFormAttributes",
            "GetIgnoreActions",
            "GetFormField",
            "GetState",
            "GetExdata",
            "GetExtdata",
            "GetExtdataServer",
            "GetExtdataColumn",
            "GetExdataColumns",
            "GetExdataItems",
            "GetExdataItemsServer",
            "GetExtdataHeader",
            "GetExtdataAttribute",
            "GetExtdataAttributes",
            "GetForm",
            "GetFormState",
            "GetFormId",
            "GetFormData",
            "GetFormDataFunction",
            "GetFormDataServer",
            "GetViewName",
            "GetViewType",
            "GetViewPagingInfo",
            "GetWhatToShow",
            "GetFormMessages",
            "GetUserInfo",
            "GetUserName",
            "GetUserRoles",
            "GetUserRolesWithoutAny",
            "GetUserUniqueId",
            "GetUserTag",
            "GetUserGorev",
            "GetUserGorevInitials",
            "GetUserMenu",
            "GetFormAttribute",
            "GetFormAction",
            "GetHostUrl",
            "GetExtDataExtdataInfo",
            "GetFormLoadingStatus",
            "GetFormError",
            "GetTimeNow",
            "GetFullPage",
            "GetLoadingFormData",
            "GetUserLoggedIn",
            "GetAxiosCount",
        ]),

        GetFormAttributesObject: function () {
            Utilities.log("GetFormAttributesObject");
            var state = this.$store.getters.GetState;
            return state.attributes;
        },

    },

    methods: {
        LeftMenuClicked(event) {
            Utilities.log('formlayout LeftMenuClicked', event);
            this.$store.commit("SetFormActions", []);
            if (event.viewname == 'yeni') {
                this.$store.commit("UpdateViewName", { viewName: '' });
                this.viewname = '';
                Utilities.log(" settimer UpdateViewName");
                setTimeout((viewName) => {
                    Utilities.log(" Timeout UpdateViewName", viewName)
                    this.$store.commit("UpdateViewName", { viewName: viewName });
                    this.$store.commit('SetWhatToShow', { show: 'form' });
                    this.viewname = viewName;

                    this.$store.commit("SetFormActions", []);
                    var name = this.$route.name;
                    this.$router.push({
                        name: name.replace('view', 'form'),
                        params: { id: 0 }
                    });
                }, 1000, event.viewname)
            }
            else {
                if (event.viewname == this.GetViewName && event.viewname != "") {
                    this.$store.commit("UpdateViewName", { viewName: '' });
                    this.viewname = '';
                    Utilities.log(" settimer UpdateViewName");
                    setTimeout((viewName) => {
                        Utilities.log(" Timeout UpdateViewName", viewName)
                        this.$store.commit("UpdateViewName", { viewName: viewName });
                        this.$store.commit('SetWhatToShow', { show: 'view' });
                        this.viewname = viewName;
                    }, 1000, event.viewname)
                }
                else {
                    Utilities.log("LeftMenuClicked set view")
                    this.$store.commit("UpdateViewName", { viewName: event.viewname });
                    this.$store.commit('SetWhatToShow', { show: 'view' });
                    this.viewname = event.viewname;
                }
            }

        },
        IsAuthPage: function (name) {
            switch (name) {
                case 'login':
                case 'register':
                case 'forgot':
                case 'resetpassword':
                case 'changepassword':
                case 'kayittalebi':
                    return true;
                default:
                    return false;
            }
        },
        IsApprovedPage: function (name) {
            switch (name) {
                case 'home':
                case 'tedarik':
                    return false;
                default:
                    return true;
            }
        },
        ShowDialog: function (message, messagetype, viewtype, onApprove, onCloseDialog) {
            var messages = {
                errors: [message],
                infoBody: '',
                messageType: messagetype,
                viewType: viewtype,
                OnApprove: function () {
                    if (onApprove != undefined) onApprove();
                },
                OnCloseDialog: function () {
                    if (onCloseDialog != undefined) onCloseDialog();
                }
            };
            this.$root.$emit('setUpDialog', messages);
        },
        ApprovedAction: function (actionInfo) {
            var self = this;
            var res = this.NeedApprove(actionInfo.action)
            if (res) {
                this.ShowDialog(res, 'C', 'dialog', function () {
                    self.FormAction(actionInfo);
                });
            } else {
                this.FormAction(actionInfo);
            }
        },

        FormLoadedMixedIn() {
            Utilities.log("FormLoadedMixedIn  type of FormLoaded", typeof this.FormLoaded, this.GetState)
            if (typeof this.FormLoaded == "function")
                this.FormLoaded();
        },
        LoadFormData: function (formid) {
            this.actionBarWait = false;
            this.$store.commit("ClearForm");
            var payload = {};
            payload.formid = formid;
            payload.form = this.GetForm;
            payload.ignoreActions = this.GetIgnoreActions;
            Utilities.log("LoadFormData dispatch:", payload, " form:", this.form);
            var self = this;

            this.$store.commit('SetLoadingFormData', true);
            this.$store.commit('SetWhatToShow', { show: 'form' });
            this.$store.dispatch('GetFormData', payload)
                .then(
                    (parm) => {
                        Utilities.log("GetFormData Loadform in Mixedin:", parm, this.GetState);
                        self.$store.commit('ClearLoadingFormDataExpiresAt');
                        if (parm != undefined && parm.data != undefined && parm.data.errorCode == 0) {
                            if (!parm.data.yetkili) {
                                self.$store.commit('SetWhatToShow', { show: 'view' });
                                self.ShowDialog(this.$root.$i18n.t('EM_UnauthorizedToView'), 'E', 'dialog');
                                self.BackToView();
                                return;
                            }
                            self.$store.commit('SetLoadingFormData', false);

                            var tables = self.$store.getters.GetExtdata;

                            for (var i = 0; i < tables.length; i++) {
                                Utilities.log("GetFormData Extdata in Mixedin:", tables[i]);
                                var table = tables[i].tableName;
                                self.$store.dispatch("GetExtData", table)
                                    .then(response => {
                                        Utilities.log("Got Extdata", response);
                                        if (response.data.errorCode == 0) {
                                            if (typeof self.ExtDataLoaded == "function") self.ExtDataLoaded(response.tableName);
                                        }
                                        else {
                                            self.ShowDialog(response.data.errorMessage, 'E', 'dialog');
                                        }
                                    })
                                    .catch(err => {
                                        Utilities.log("Hata:", err);
                                        var message = this.$root.$i18n.t('EM_UnexpectedErrorTryAgain');
                                        self.ShowDialog(message, 'E', 'dialog');
                                    });
                            }
                            self.FormLoaded();
                        } else {
                            var message;
                            if (parm.data.errorMessage != undefined) {
                                message = parm.data.errorMessage;
                            } else{
                                message = this.$root.$i18n.t('EM_UnexpectedErrorTryAgain');
                            }
                            self.BackToView();
                            self.ShowDialog(message, 'E', 'dialog');
                        }

                    }
                ).catch((err) => {
                    //alert("catch error:" + err)
                    var message = this.$root.$i18n.t('EM_UnexpectedErrorTryAgain');
                    self.BackToView();
                    self.ShowDialog(message, 'E', 'dialog');
                    self.$store.commit('SetLoadingFormData', false);
                })
        },
        MenuClicked: function (rowdata) {
            Utilities.log("MenuClicked  -------------------------&&&&&&&&&&&&&&&&& ", rowdata, this.GetState);
            var viewname = rowdata.viewname;
            var type = rowdata.type;
            if (viewname == 'yeni') {
                Utilities.log("Formloaded type:", typeof this.FormLoaded);
                this.validateError = "";
                this.LoadFormData(0);
            }
            else if (type == 'view') {
                Utilities.log(" view type = view");
                this.viewName = rowdata.viewname;
                //Ayni view' tiklandiginda watch �alismiyor
                // Çalismaya zorlamak için bosaltip tekrar yüklüyoruz
                // View bos oldugunda watch birsey yapmiyor
                // Bkz: view.vue watch viewname
                if (rowdata.viewname == this.GetViewName) {
                    this.$store.commit("UpdateViewName", { viewName: '' });
                    Utilities.log(" settimer UpdateViewName");
                    setTimeout(() => {
                        Utilities.log(" Timeout UpdateViewName")
                        this.$store.commit("UpdateViewName", { viewName: rowdata.viewname });
                        this.$store.commit('SetWhatToShow', { show: 'view' });
                    }, 1000)
                }
                else {
                    Utilities.log(" settimer UpdateViewName2 ", rowdata.viewname);
                    this.$store.commit("UpdateViewName", { viewName: rowdata.viewname });
                    this.$store.commit('SetWhatToShow', { show: 'view' });
                }


            }
            else if (type == 'url') {
                //open url in a new window
            }

        },
        Inventory_Item_Property_data_AL: function () {
            Utilities.log("In Inventory_Item_Property_data");
        },
        ViewClicked: function (rowdata) {
            this.validateError = "";
            Utilities.log('clicked on  Row:', rowdata);
            Utilities.log('rowdata.viewname:', rowdata.viewname);
            Utilities.log("rowdata.item.id ", rowdata.row.item.id);
            // Do View click
            if (typeof this.IsDataView == "function") {
                var res = this.IsDataView(rowdata.view)
                if (!res) return;
            }
            var viewtype = this.GetViewType;
            Utilities.log("view type:", viewtype)
            if (viewtype == 'view' || viewtype == 'external') {
                this.$store.commit("SetFormActions", []);
                var id = rowdata.row.item.id;
                var name = this.$route.name;
                this.$router.push({
                    name: name.replace('view', 'form'),
                    params: { id: id }
                });
            }
        },
        BackToView() {
            Utilities.log("BackToView");
            this.$store.commit('SetFormActions', []);
            var name = this.$route.name.replace('form', 'view');
            this.$router.push({
                name: name,
            });
        },
        GetActionButtonStyle: function (action) {
            //var result = "margin-right:5px; font-weight:600;";
            //if (action.color && action.color != '') result += " color:" + action.color + "; ";
            //if (action.backgroundcolor && action.backgroundcolor != '')
            //	result += " background-color:" + action.backgroundcolor + "; ";
            //return result;
            return "";
        },
        UpdateFormField: function (payload, tableName) {
            //payload
            //  column ( colum definition)
            //  value ( updated value)
            //  tableName (formdata = form_data)
            //  id table i�inde id
            Utilities.log("UpdateFormField:" + JSON.stringify(payload));
            var oldvalue = this.GetFormField(payload.column.name);
            payload.tableName = tableName;
            this.$store.commit("SetField", payload);
            //Autocomplete alanlarda g�ncellenecek diger alanlari da g�ncelleme gerek
            //Payload i�inde bu bilgiler de geliyor olmali
            var type = payload.column.type;
            if (type == "auto") {
                if (payload.fieldValue == "") {
                    //Field cleared.
                    // outputparametesideki alanlari bu alanin Field_Changed callback'inde clear et
                }
                else {
                    // outpu alanindaki güncellemeleri de yap
                    var selectedObject = payload.selectedObject; // selected object
                    if (typeof selectedObject == "object") {
                        var outputs = payload.column.outputs;
                        for (var key in outputs) {
                            var parm = outputs[key];
                            var val = selectedObject.value[key];
                            if (parm != "") {
                                this.$store.commit("SetField", {
                                    fieldName: parm,
                                    fieldValue: val,
                                    tableName: tableName

                                })
                            }
                        }
                    }
                }
                var pload = {};
                pload.fieldName = payload.column.name;
                pload.attribute = "items";
                pload.value = [];
                this.$store.commit("SetFormAttributes", pload);
                payload.fieldValue = this.GetFormField(payload.column.name);
            }
            var columnName = payload.fieldName;
            try {
                payload.oldvalue = oldvalue;
                var funcName = (tableName != "formdata") ? (tableName + "_" + columnName + "_Field_Changed") : (columnName + "_Field_Changed")
                Utilities.log("Function Name : ", funcName);

                if (typeof this[funcName] === "function") this[funcName](payload);
            } catch (ex) {
                var a=ex;
            }
        },

        UpdateExtDataValue(payload) {
            this.$store.commit('UpdateExtDataField', payload);
        },
        UpdateExtData: function (payload, tableName) {
            Utilities.log("UpdateExtData in mixin Emit", payload);
            payload.tableName = tableName;

            this.$store.commit("UpdateExtData", payload);

            var funcName = payload.tableName + "_Row_Changed";
            if (typeof this[funcName] === "function") this[funcName](payload);
        },
        AddRow2ExtData(rowData, table) {
            Utilities.log("AddRow2ExtData", rowData, table);
            this.$store.commit("AddRow2ExtData", { tableName: table, rowData: rowData });
            var funcName = table + "_After_AddRow";
            if (typeof this[funcName] === "function") {
                var items = this.GetExdataItems(table);
                // id alanı boş olan kayıtların id alanını 0 olarak güncelle
                for (var item of items) {                    
                    var id = item.id;
                    if (id == '') {
                        var payload = {};
                        payload.columnName = 'id';
                        payload.fieldValue = 0;
                        payload.rowid = item.rowid;
                        payload.tableName = table;
                        Utilities.log('update id . rowid:', item.rowid);
                        this.$store.commit('UpdateExtDataField', payload);
                    }
                }
                this[funcName](items);
            }
        },
        HeaderButtonClicked(event, columnName) {
            return;
        },
        SearchInputChanged: function (payload, tableName) {
            payload.tableName = tableName;
            Utilities.log("SearchInputChanged in FormMix:", payload, tableName);
            if (payload.column.type == "grid") {

                payload.tableName = payload.fieldName;
                payload.fieldName = payload.search.fieldName;
                payload.column = payload.search.column;
                payload.search = payload.search.search;
            }

            //var tableName = payload.tableName || "formdata";

            var column = payload.column; //search.column.type
            if (column.type != "auto") return;
            var req = {};
            req.payload = payload;
            req.payload.parameters = column.parameters;
            if (tableName == "$parameters$")
                req.queryName = "FormParameters_" + payload.fieldName;
            else if (column.queryName)
                req.queryName = column.queryName;
            else
                req.queryName = (tableName == "formdata" ? this.GetForm : tableName) + "_" + column.name;
            req.url = column.url;
            req.label = column.itemText;
            req.notAuthorizedRequest = column.notAuthorizedRequest;
            req.success = payload.success;
            req.error = null;
            Utilities.log("Dispatch GetLOV:" + JSON.stringify(req));
            this.$store.dispatch("GetLOV", req);

        },
        IsFieldEditable: function (column) {
            var edit = column.edit || '';
            var st = this.GetFormState;
            if (typeof st == 'undefined' || st == null || st == '') st = '0';
            return edit.indexOf(st) != -1;
        },
        ValidateField: function (value, column, action)
        {
            if (!this.IsFieldEditable(column)) {
                return "";
            }
            var result = "";
            var type = column.type;
            var min = column.validateMin || 0;
            var max = column.validateMax || 0;
            var columnLabel = this.$root.$i18n.t(column.label);
            if (min > 0) {
                if (type == 'int' || type == 'number') {
                    if (isNaN(Number(value))) {
                        result = columnLabel + " " + this.$root.$i18n.t("VE_WarnOnlyNumbers");
                    }
                    var v = Number(value);
                    if (type == 'int' && parseInt(value) != v) result = this.$root.$i18n.t('VE_WarnFieldMustBeInteger', { field: columnLabel });
                    if (min > 0 && v < min) result = column.validateErrorMin || (this.$root.$i18n.t('VE_WarnFieldMustBeMin', { field: columnLabel, min: min }));
                    if (max > 0 && v > max) result = column.validateErrorMax || (this.$root.$i18n.t('VE_WarnFieldMustBeMax', { field: columnLabel, max: max }));
                }

                if (type == "select") {
                    if (value == null || value == '') {
                        result = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: columnLabel });
                    }
                }

                if (type == "auto" || type == "autocomplete") {
                    if (value == null || value == '') {
                        result = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: columnLabel });
                    }
                }

                if (type == "date") {
                    // var mindate = locale ile date e d�n�st�rme ekle
                    if (value == "") {
                        result = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: columnLabel });
                    }
                }
                if (column.name != 'history' && (type == "text" || type == "textarea")) {
                    if (min == 1) {
                        if (min > 0 && value.length < min) result = column.validateErrorMin || (this.$root.$i18n.t('VE_WarnFieldCannotEmptyEnter', { field: columnLabel}));
                    }
                    else {
                        if (min > 0 && value.length < min) result = column.validateErrorMin || (this.$root.$i18n.t('VE_WarnFieldMustBeMinCharacter', { field: columnLabel, min: min }));
                    }
                    
                    if (max > 0 && value.length > max) result = column.validateErrorMin || (this.$root.$i18n.t('VE_WarnFieldMustBeMaxCharacter', { field: columnLabel, max: max }));
                }

                if (type == 'file') {
                    if (this.GetFormAttribute(column.name, 'UploadStatus') == 'FileSelected') {
                        result = this.$root.$i18n.t('VE_WarnFieldSelectedFileNotUpload', { field: columnLabel });
                    }
                    if (value == '')
                    {
                        result = this.$root.$i18n.t('VE_NoFileSelected', { field: columnLabel });
                    }
                }

                if (type == 'checbox' || type == 'confirmbox') {
                    if (value == 0 && min > 0) {
                        result = this.$root.$i18n.t(column.validateErrorMin);
                    }
                }

                if (type == 'history')
                {
                    var compareaction = column.forwardRequiredAction;

                    if (typeof (column.forwardRequiredAction) == 'string')
                    {
                        compareaction = [column.forwardRequiredAction];
                    }

                    if (compareaction.includes(action))
                    {
                        var st = this.GetFormState;
                        if (typeof st == 'undefined' || st == null || st == '') st = '0';

                        var editable = (column.forwardEdit || '').indexOf(st) != -1;
                        var required = (column.forwardRequired || '').indexOf(st) != -1;

                        if (editable && required) {
                            if (this.GetFormField('oonaysicilno') == '' || this.GetFormField('oonaysicilno') == null)
                            {
                                result = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_ForwardName') });
                            }
                        }
                    }
                }

                if (result == '' && type == 'text') {
                    if (column.mask == 'email') {
                        if (!this.ValidateEmail(value)) {
                            result = this.$root.$i18n.t('VE_WarnEmailMustBeInRightFormat', { field: columnLabel });
                        }
                    }
                }
            }


            return result;
        },
        ValidateEmail: function (email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        _suppress(evt) { evt.stopPropagation(); evt.preventDefault(); },
        _drop(evt, uploadAct) {
            evt.stopPropagation(); evt.preventDefault();
            const files = evt.dataTransfer.files;
            if (files && files[0]) this.UploadItems(files[0], uploadAct);
        },
        _change(evt, uploadAct) {
            const files = evt.target.files;
            if (files && files[0]) this.UploadItems(files[0], uploadAct);
        },
        UploadItems(file, uploadAct) {
            /* Boilerplate to set up FileReader */
            const reader = new FileReader();
            reader.onload = (e) => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const exceldata = XLSX.utils.sheet_to_json(ws, { header: 1 });
                /* Update state */
                this.uploadedItemsCols = make_cols(ws['!ref']);
                this.uploadedItems = exceldata;
                this.UploadedItems_AE(exceldata, uploadAct);
            };

            reader.readAsBinaryString(file);
        },
        CloseActionResultDialog: function () {
            Utilities.log("Close dialog");
            this.actionResultDialog = false;
            this.$store.commit('SetWhatToShow', { show: 'view' });
            this.$store.commit("UpdateViewName", { viewName: this.viewName });

        },
        ShowState: function () {
            Utilities.log("State:", this.GetState)
            Utilities.log("State:", this);
            Utilities.log("Original extdata: ", this.GetExtdataServer);
            Utilities.log("Original FormData", this.GetFormDataServer);
            Utilities.log("ENV:", process.env.NODE_ENV);
        },
        FormAction: function (act) {
            this.actionBarWait = true;
            let msg = "";
            if (typeof this.ValidateForm == "function") {
                msg = this.ValidateForm(act);
                if (typeof msg == "undefined") msg = "";
            }
            var self = this;
            if (msg == "") {

                // Hata yok. Devam et;
                this.$store.dispatch('SendFormData', { action: act })
                    .then((response) => {
                        this.actionBarWait = false;
                        this.$store.commit('ClearLoadingFormDataExpiresAt');
                        if (response.data.errorCode != 0) {
                            Utilities.log("Got Error From SendFormData:", response.data.actionResult);
                            if (response.data.actionResult != '') {
                                self.ClearFieldErrors();
                                self.ShowDialog(response.data.actionResult, 'E', 'notification');
                            } else {
                                self.ClearFieldErrors();
                                self.ShowDialog(response.data.errorMessage, 'E', 'notification');
                                
                            }
                            return;
                        }

                        this.ClearFieldErrors();

                        Utilities.log("Got Success From SendFormData:", response.data.actionResult);
                        this.$store.commit('SetFormActions', []);
                        self.ShowDialog(this.$root.$i18n.t('DM_TransactionCompletedSuccessfully'), 'B', 'notification');
                        
                        //işlem başarıyla tamamlandı formdan. çağır. AfterExecute

                        let afterExecuteResult = true;
                        if (typeof this.AfterExecute == "function") {
                            var afterMsg = this.AfterExecute(response);
                            if (typeof afterMsg != "undefined") afterExecuteResult = afterMsg;
                        }

                        if (afterExecuteResult === true) {
                            self.BackToView();
                        }
                    })
                    .catch((error) => {
                        Utilities.log("FormAction catch error:", error);
                        var message = this.$root.$i18n.t('EM_UnexpectedErrorTryAgain');
                        self.ShowDialog(message, 'E', 'dialog');
                        self.actionBarWait = false;
                        self.ClearFieldErrors();

                    });
            }
            else {
                this.actionBarWait = false;
                // Validation hatasi var
                Utilities.log("Validation error:", msg);
                var messages = {
                    errors: msg,
                    infoBody: '',
                    messageType: 'E',
                    viewType: 'notification',
                };
                this.$root.$emit('setUpDialog', messages);
            }
        },

        AddFormAction(act) {
            // { sira: 0, action: 'Transfer', label: 'Transfer', color: '' }
            //Check if already there
            var actions = this.GetFormAction;
            var ix = actions.findIndex((a) => { return a.action == act.action });
            if (ix != -1) return; // zaten var

            //Action listede yok, Ekle
            actions.push(act);
            this.$store.commit('SetFormActions', actions)
        },
        RemoveFormAction(actionName) {

            //Check if already there
            var actions = this.GetFormAction;
            var ix = actions.findIndex((a) => { return a.action == actionName });
            if (ix == -1) return; // Zaten yok
            //Action listede var, �ikart
            actions.splice(ix, 1);
            this.$store.commit('SetFormActions', actions)
        },

        ClearFormFields() {
            this.$store.commit('ClearForm');
        },

        SetFieldError(fieldName) {
            this.$store.commit('SetFormAttributes', { fieldName: fieldName, attribute: 'error', value: true });
        },
        ClearFieldError(fieldName) {
            this.$store.commit('SetFormAttributes', { fieldName: fieldName, attribute: 'error', value: false });
        },
        ClearFieldErrors() {
            const columns = this.$store.getters.GetState.Columns;
            for (var c in columns) {
                var column = columns[c];
                this.$store.commit('SetFormAttributes', { fieldName: column.name, attribute: 'error', value: false });
            }
        },
        SetFieldReadOnly(fieldName) {
            this.$store.commit('SetFormAttributes', { fieldName: fieldName, attribute: 'readonly', value: true });
        },
        ClearFieldReadOnly(fieldName) {
            this.$store.commit('SetFormAttributes', { fieldName: fieldName, attribute: 'readonly', value: false });
        },
        SetFieldHide(fieldName) {
            this.$store.commit('SetFormAttributes', { fieldName: fieldName, attribute: 'hide', value: true });
        },
        ClearFieldHide(fieldName) {
            this.$store.commit('SetFormAttributes', { fieldName: fieldName, attribute: 'hide', value: false });
        },
        SetFieldHidden(fieldName) {
            this.$store.commit('SetFormAttributes', { fieldName: fieldName, attribute: 'hidden', value: true });
        },
        ClearFieldHidden(fieldName) {
            this.$store.commit('SetFormAttributes', { fieldName: fieldName, attribute: 'hidden', value: false });
        },
        SetFieldDisabled(fieldName) {
            this.$store.commit('SetFormAttributes', { fieldName: fieldName, attribute: 'disabled', value: true });
        },
        ClearFieldDisabled(fieldName) {
            this.$store.commit('SetFormAttributes', { fieldName: fieldName, attribute: 'disabled', value: false });
        },
        SetFieldRequired(fieldName) {
            this.$store.commit('SetFormAttributes', { fieldName: fieldName, attribute: 'validateMin', value: 1 });
        },
        ClearFieldRequired(fieldName) {
            this.$store.commit('SetFormAttributes', { fieldName: fieldName, attribute: 'validateMin', value: 0 });
        },
        SetExtDataFieldError(tableName, id, fieldName) {
            this.$store.commit('SetFieldAttributes', { fieldName: fieldName, tableName: tableName, id: id, attributeName: 'error', value: true });
        },

        SetExtDataColumnAttribute(tableName, fieldName, attribute, value) {
            this.$store.commit('SetExtDataColumnAttributes', { fieldName: fieldName, tableName: tableName, attributeName: attribute, value: value });
        },

        ClearExtDataFieldError(tableName, id, fieldName) {
            this.$store.commit('SetFieldAttributes', { fieldName: fieldName, tableName: tableName, id: id, attributeName: 'error', value: false });
        },

        SetExtDataFieldReadOnly(tableName, id, fieldName) {
            this.$store.commit('SetFieldAttributes', { fieldName: fieldName, tableName: tableName, id: id, attributeName: 'readonly', value: true });
        },

        ClearExtDataFieldReadOnly(tableName, id, fieldName) {
            this.$store.commit('SetFieldAttributes', { fieldName: fieldName, tableName: tableName, id: id, attributeName: 'readonly', value: false });
        },

        getElementBoundingClientRect(elem) {
            var bounding = elem.getBoundingClientRect();

            var boundingCopy = {
                top: bounding.top,
                left: bounding.left,
                bottom: bounding.bottom,
                right: bounding.right,
                height: bounding.height,
                width: bounding.width,
            };
            return boundingCopy;
        },
        isOutOfViewport(elem, top, height, left) {

            // Get element's bounding
            var bounding = this.getElementBoundingClientRect(elem);

            if (top != undefined && height != undefined) {
                bounding.top = top;
                bounding.bottom = height + top;
            }

            if (left != undefined) {
                bounding.left = left;
                bounding.right = bounding.width + left;
            }

            // Check if it's out of the viewport on each side
            var out = {};
            out.top = bounding.top < 0;
            out.left = bounding.left < 0;
            out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
            out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
            out.any = out.top || out.left || out.bottom || out.right;
            out.all = out.top && out.left && out.bottom && out.right;

            return out;

        }

    },
    watch:
    {

        view(nval, oval) {
            Utilities.log(" WATCH viewName", nval, oval);
            var payload = {};
            payload.viewname = nval;
            payload.type = 'view';
            this.MenuClicked(payload);
        },
        formid(nval, oval) {
            Utilities.log("WATCH FormId", nval, oval);
        }
    }

}
