<template>
    <div v-if="!Hide">
        <div v-if="column.type != 'grid'">
            <div v-if="!Editable">
                <div v-if="column.type != 'fileHistory' && column.type != 'alert'" class="caption font-weight-thin" :class="{fieldHidden: Hidden }">
                    {{ $t(column.label)}}
                </div>
                <v-alert v-if="column.type == 'alert'" class="clearMarginBottom"
                         :type="column.messageType" :class="{fieldHidden: Hidden }">
                    <span class="notification" v-html="$t(column.message)"></span>
                </v-alert>
                <div v-else-if="column.type == 'history'" :class="{fieldHidden: Hidden }">
                    <history :editable="Editable"
                             :label="$t(column.label)"
                             :column="column"
                             :invalue="value"></history>
                </div>
                <div v-else-if="column.type == 'fileHistory'" :class="{fieldHidden: Hidden }">
                    <fileHistory></fileHistory>
                </div>
                <div v-else-if="column.type == 'html'" :class="{fieldHidden: Hidden }">
                    <p class="card-text" v-html="value" />
                </div>
                <upload v-else-if="column.type == 'file'"
                        @change="UpdateValueFile($event, column)"
                        @changeAttribute="ChangeAttribute($event)"
                        :editable="Editable"
                        :params="column"
                        :invalue="value"
                        :class="{ fielderror: Error, fieldHidden: Hidden }"
                        class="my-1"
                        label="Label"
                        :readonly="Readonly"
                        :approved="Approved"
                        :disabled="Disabled"></upload>
                <v-chip v-else-if=" column.type == 'url'" @click="OpenUrl()" label solo flat style="border: 1px solid #bdbdbd" pill hide-details :class="{fieldHidden: Hidden }">{{  $t(column.label)
                    }} | {{ value }}</v-chip>
                    <div class="body-1" v-else-if="column.type == 'select'" :class="{fieldHidden: Hidden }">
                        {{ GetSelectText }}
                    </div>
                    <div v-else-if="column.type != 'image' && column.type != 'link' && column.type != 'checkbox'" :class="{fieldHidden: Hidden }">

                        <div :class="{'body-2': (value == ''),'body-1': (value != ''), 'font-weight-thin': (value == ''), 'unspecified': (value == ''), 'font-italic': (value == ''), fieldHidden: Hidden  }" v-if="column.type == 'datetime'">
                            {{ value | FormatDateTimeTR(column,$t('ST_Undefined'),$t('ST_Indefinite')) }}
                        </div>
                        <div :class="{'body-2': (value == ''),'body-1': (value != ''), 'font-weight-thin': (value == ''), 'unspecified': (value == ''), 'font-italic': (value == ''), fieldHidden: Hidden  }" v-else-if="column.type == 'date'">
                            {{ value | FormatDateTR($t('ST_Undefined'),$t('ST_Indefinite')) }}
                        </div>
                        <div :class="{'body-2': (value == ''),'body-1': (value != ''), 'font-weight-thin': (value == ''), 'unspecified': (value == ''), 'font-italic': (value == ''), fieldHidden: Hidden  }" v-else>
                            {{ value | FormatColumn(column, this.GetLocale,$t('ST_Undefined'),$t('OP_PleaseSelect')) }}
                        </div>
                    </div>
                    <v-chip v-else label solo flat style="border: 1px solid #bdbdbd" pill hide-details :class="{fieldHidden: Hidden }">{{  $t(column.label) }} | {{ value }}</v-chip>
                </div>
            <div v-else>
                <v-row v-if="column.type == 'confirmbox'" class="d-flex align-center">
                    <v-btn color="primary" icon v-on:click="PrepareDocumentDialog(column.document)">
                        <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <v-checkbox v-model="value"
                                color="primary"
                                :class="{ fielderror: Error, fieldHidden: Hidden }"
                                @change="UpdateCheckbox($event, column)"
                                :disabled="Disabled"
                                :error="Error">
                        <template v-slot:label>
                            <span class="body-2" style="padding-top: 10px">{{$t(column.document + 'Description')}}</span>
                        </template>
                    </v-checkbox>
                </v-row>
                <v-text-field v-if="(column.type == 'text' || column.type == 'url')"
                              hide-details
                              outlined
                              @change="UpdateValue($event, column)"
                              v-model="value"
                              :class="{ fielderror: Error, fieldDisabled: Disabled, fieldHidden: Hidden  }"
                              class="my-1"
                              :placeholder="PlaceHolder"
                              :label="Label"
                              :error="Error"
                              :readonly="Readonly"
                              :disabled="Disabled"
                              :append-icon="Approved == false ? 'mdi-timer-sand' : ''"
                              @click:append="click()"
                              color="primary"
                              :background-color="Disabled ? 'grey lighten-3' : 'white'">
                    <template v-if="column.appendbutton && !Disabled " v-slot:append-outer>
                        <v-btn style="margin-top:-6px !important" color="primary"
                               @click="FieldButtonClicked(column.appendbutton)">
                            <v-icon v-if="column.appendbuttonpreicon">{{column.appendbuttonpreicon}}</v-icon>
                            {{column.appendbutton}}
                            <v-icon v-if="column.appendbuttonappendicon">{{column.appendbuttonappendicon}}</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
                <v-text-field v-if="(column.type == 'number' || column.type == 'int')"
                              outlined
                              :background-color="Disabled ? 'grey lighten-3' : 'white'"
                              hide-details
                              @change="UpdateValue($event, column)"
                              :class="{ fielderror: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
                              class="my-1"
                              v-model="value"
                              v-on:keydown="queryForKeywords($event)"
                              :placeholder="$t(column.label)"
                              :label="Label"
                              :error="Error"
                              :readonly="Readonly"
                              :disabled="Disabled"
                              :append-icon="Approved == false ? 'mdi-timer-sand' : ''"
                              color="primary">

                    <template v-if="column.appendbutton && !Disabled " v-slot:append-outer>
                        <v-btn style="margin-top:-6px !important" color="primary"
                               @click="FieldButtonClicked(column.appendbutton)">
                            <v-icon v-if="column.appendbuttonpreicon">{{column.appendbuttonpreicon}}</v-icon>
                            {{column.appendbutton}}
                            <v-icon v-if="column.appendbuttonappendicon">{{column.appendbuttonappendicon}}</v-icon>
                        </v-btn>
                    </template>

                </v-text-field>
                <div v-if="column.type == 'history'">
                    <history :editable="Editable"
                             :label="Label"
                             :column="column"
                             @change="UpdateNote($event)"
                             :error="Error"
                             :class="{ fielderror: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
                             :invalue="value"
                             v-model="value"
                             :isPortal="column.isPortal"></history>
                </div>
                <v-textarea v-if="column.type == 'textarea'"
                            outlined
                            :background-color="Disabled ? 'grey lighten-3' : 'white'"
                            hide-details
                            @change="UpdateValue($event, column)"
                            :class="{ fielderror: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
                            class="my-1"
                            v-model="value"
                            :placeholder="$t(column.label)"
                            :label="Label"
                            :error="Error"
                            :disabled="Disabled"
                            :readonly="Readonly"
                            :append-icon="Approved == false ? 'mdi-timer-sand' : ''"
                            color="primary">
                    <template v-if="column.appendbutton && !Disabled " v-slot:append-outer>
                        <v-btn style="margin-top:-6px !important" color="primary"
                               @click="FieldButtonClicked(column.appendbutton)">
                            <v-icon v-if="column.appendbuttonpreicon">{{column.appendbuttonpreicon}}</v-icon>
                            {{column.appendbutton}}
                            <v-icon v-if="column.appendbuttonappendicon">{{column.appendbuttonappendicon}}</v-icon>
                        </v-btn>
                    </template>
                </v-textarea>
                <v-checkbox v-if="column.type == 'checkbox' && Editable"
                            solo flat
                            :background-color="Disabled ? 'grey lighten-3' : 'white'"
                            hide-details
                            :class="{ fielderror: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
                            class="align-self-stretch"
                            v-model="value"
                            :disabled="Disabled"
                            :error="Error"
                            @change="UpdateCheckbox($event, column)"
                            color="primary">
                    <template v-slot:label>
                        <span class="body-2" style="padding-top: 10px">{{$t(column.label)}}</span>
                    </template>
                    <template v-if="column.appendbutton && !Disabled " v-slot:append-outer>
                        <v-btn style="margin-top:-6px !important" color="primary"
                               @click="FieldButtonClicked(column.appendbutton)">
                            <v-icon v-if="column.appendbuttonpreicon">{{column.appendbuttonpreicon}}</v-icon>
                            {{column.appendbutton}}
                            <v-icon v-if="column.appendbuttonappendicon">{{column.appendbuttonappendicon}}</v-icon>
                        </v-btn>
                    </template>
                </v-checkbox>
                <v-checkbox v-if="column.type == 'checkbox' && !Editable"
                            solo flat style="border: 1px solid #bdbdbd"
                            :background-color="Disabled ? 'grey lighten-3' : 'white'"
                            hide-details
                            :class="{ fielderror: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
                            class="align-self-stretch"
                            v-model="value"
                            disabled
                            :error="Error"
                            @change="UpdateCheckbox($event, column)"
                            color="primary"></v-checkbox>


                <v-select v-if="column.type == 'select'" v-show="Editable"
                          outlined
                          menu-props="auto"
                          hide-details
                          :multiple="column.multiple  ? column.multiple : false"
                          :background-color="Disabled ? 'grey lighten-3' : 'white'"
                          :readonly="Readonly"
                          :disabled="Disabled"
                          :error="Error"
                          :class="{ fielderror: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
                          class="my-1"
                          :append-icon="Approved == false ? 'mdi-timer-sand' : 'mdi-menu-down'"
                          :items="GetSelectOptions"
                          v-model="value"
                          item-text="text"
                          item-value="value"
                          :placeholder="$t('OP_PleaseSelect')"
                          :label="Label"
                          @change="UpdateSelect($event, column)"
                          color="primary">
                    <template v-if="column.appendbutton && !Disabled " v-slot:append-outer>
                        <v-btn style="margin-top:-6px !important" color="primary"
                               @click="FieldButtonClicked(column.appendbutton)">
                            <v-icon v-if="column.appendbuttonpreicon">{{column.appendbuttonpreicon}}</v-icon>
                            {{column.appendbutton}}
                            <v-icon v-if="column.appendbuttonappendicon">{{column.appendbuttonappendicon}}</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:append-outer>
                    </template>
                </v-select>
                <v-autocomplete v-if="column.type == 'autocomplete' && Editable"
                                :chips="column.multiple  ? column.multiple : false"
                                outlined
                                hide-details
                                :deletable-chips="column.multiple  ? column.multiple : false"
                                :multiple="column.multiple  ? column.multiple : false"
                                :readonly="Readonly"
                                :disabled="Disabled"
                                :items="column.items"
                                :item-text="column.itemText"
                                :item-value="column.valueField"
                                v-model="value"
                                :invalue="value"
                                :error="Error"
                                @focus="changeSearchInputToTriggerFilter()"
                                @click="changeSearchInputToTriggerFilter()"
                                @click:clear="changeSearchInputToTriggerFilter()"
                                :filter="column.filter"
                                :placeholder="$t('OP_PleaseSelect')"
                                :label="Label"
                                :class="{ fielderror: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
                                class="my-1"
                                @change="UpdateAutoComplete($event, column)"
                                @search="SearchInputChangedDebounced($event)"
                                :search-input="column.searchInput"
                                :append-icon="Approved == false ? 'mdi-timer-sand' : ''">
                </v-autocomplete>
                <checkboxgroup v-if="column.type == 'checkboxgroup' && Editable"
                               :value="value"
                               :params="column"
                               @change="UpdateValue($event, column)"
                               :readonly="Readonly"
                               :disabled="Disabled"
                               :error="Error">
                </checkboxgroup>
                <money v-if="column.type == 'money' && Editable"
                       @change="UpdateValueMoney($event, column)"
                       :params="column"
                       v-model="value"
                       :invalue="value"
                       :doviz="currency"
                       :class="{ fielderror: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
                       class="my-1"
                       :label="Label"
                       :readonly="Readonly"
                       :disabled="Disabled"></money>
                <upload v-if="column.type == 'file'"
                        @change="UpdateValueFile($event, column)"
                        @changeAttribute="ChangeAttribute($event)"
                        :editable="Editable"
                        :params="column"
                        :invalue="value"
                        :class="{ fielderror: Error, fieldHidden: Hidden }"
                        :error="Error"
                        class="my-1"
                        :label="Label"
                        :readonly="Readonly"
                        :approved="Approved"
                        :disabled="Disabled"></upload>
                <imageupload v-if="column.type == 'image'"
                             @change="UpdateValueFile($event, column)"
                             :editable="Editable"
                             :params="column"
                             :invalue="value"
                             :class="{ fielderror: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
                             :label="Label"
                             :readonly="Readonly"
                             :disabled="Disabled"></imageupload>
                <v-menu v-if="column.type == 'date' && Editable"
                        v-model="dateopen"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined
                                      :background-color="Disabled ? 'grey lighten-3' : 'white'"
                                      hide-details
                                      :append-icon="Approved == false ? 'mdi-timer-sand' : 'mdi-calendar-month'"
                                      :value="value | FormatDateTR"
                                      @click="dateopen = !dateopen"
                                      :error="Error"
                                      :disabled="Disabled"
                                      readonly
                                      clearable
                                      @click:clear="ClearDate($event,column)"
                                      :class="{ fielderror: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
                                      class="my-1"
                                      :label="Label"
                                      color="primary"
                                      v-bind="attrs"
                                      v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-if="dateopen"
                                   v-model="value"
                                   :disabled="Disabled"
                                   @change="UpdateValue($event, column)"
                                   @input="menu2 = false"
                                   color="primary lighten-2"
                                   header-color="primary"
                                   :min="GetMinMaxDate(column.mindate)"
                                   :max="GetMinMaxDate(column.maxdate)"
                                   :allowed-dates="allowedDates"
                                   :first-day-of-week="1"
                                   locale="tr"></v-date-picker>
                </v-menu>
                <autocomplete v-if="column.type == 'auto' && Editable"
                              outlined
                              v-model="value"
                              :invalue="value"
                              :items="GetAttributes"
                              :loading="isLoading"
                              :itemtext="column.searchText"
                              :itemvalue="column.searchText"
                              :minLength="column.minLength"
                              :error="Error"
                              :class="{ fielderror: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
                              class="my-1"
                              :readonly="Readonly"
                              :disabled="Disabled"
                              :approved="Approved"
                              :label="Label"
                              :params="column"
                              @search="SearchInputChangedDebounced($event)"
                              @change="UpdateValueAutoComplete($event, column)">
                </autocomplete>
                <v-btn v-if="column.type == 'link'" block depressed solo flat style="border: 1px solid #bdbdbd" color="primary" @click="GetLink()" append-icon="mdi-cursor">
                    {{  $t(column.label) }}
                    <v-icon right dark>
                        mdi-cursor-default-click
                    </v-icon>
                </v-btn>

                <v-btn v-if="column.type == 'button'" @click="ButtonClick(column)" :color="column.buttoncolor">
                    {{  $t(column.buttontext) }}
                </v-btn>

            </div>
        </div>
        <div v-else>
            <grid style="display: inline-block;"
                  v-if="column.type == 'grid'"
                  :tableName="column.name"
                  :columns="GetExdataColumns(column.name)"
                  :data="GetExdataItems(column.name)"
                  :header="Label"
                  :showselect="column.showselect"
                  :showExportToExcelButton="column.showExportToExcelButton"
                  @change="UpdateValueGrid($event, column.name)"
                  :class="{ gridError: Error, fieldDisabled: Disabled, fieldHidden: Hidden }"
                  :error="Error"
                  @selected="Selected($event)"
                  @SearchInputChanged="SearchInputChanged($event, column)"
                  @RowButtonClicked="RowButtonClicked($event, column)"
                  @AddRow="AddRow2ExtData($event, column.name)"
                  :attributes="GetExtdataAttributes(column.name)" :buttons="column.buttons"
                  @HeaderButtonClicked="HeaderButtonClicked($event, column)"
                  :addrow="column.edit.indexOf(GetFormState) != -1  && ShowGridAddRow(column) ? 'AL_Add' : ''" />
            <span>{{DumpGrid('grid column:', column, GetState)}}</span>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue';
    import upload from './upload';
    Vue.component('upload', upload);

    import imageupload from './ImageUpload';
    Vue.component('imageupload', imageupload);

    import money from './Money';
    Vue.component('money', money);

    import autocomplete from './AutoComplete';
    Vue.component('autocomplete', autocomplete);

    import grid from './grid';
    Vue.component('grid', grid);

    import dropdown from './dropdown';
    Vue.component('dropdown', dropdown);

    import numeral from 'numeral';
    import moment from 'moment';

    import history from '@/components/history.vue';
    Vue.component('history', history);

    import fileHistory from '@/components/FileHistory.vue';
    Vue.component('fileHistory', fileHistory);

    import terms from '@/components/AgreementDialog.vue';
    Vue.component('terms', terms);

    import debounce from 'debounce';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import Utilities from '@/Utilities.js'
    import { forEach } from 'core-js/fn/dict';


    export default {
        name: 'field',
        mixins: [FormMixIn],
        props: {
            type: String,
            field: String,
            label: String,
            column: Object,
            invalue: [String, Number, Date, Object, Array],
            doviz: String,
            state: String,
            grid: String,
            attributes: Object,
            rules: Array,
            buttons: Object,
        },
        data() {
            return {
                items: [],
                isLoading: false,
                model: '',
                date: null,
                menu2: false,
                value: '',
                currency: '',
                dateopen: false,
                //rule: "rules" //[v => v &&  v.length >=3 || 'Hata']
            };
        },
        computed: {
            Editable: function () {
                var edit = this.column.edit || '';
                var st = this.state;
                if (typeof st == 'undefined' || st == null || st == '') st = '0';
                var editable = edit.indexOf(st) != -1;
                //Utilities.log("Editable=", edit," state", this.state, "column:", this.column);
                return editable;
            },
            GetAttributes: function () {
                return this.attributes.items;
            },
            Error: function () {
                return this.attributes ? this.attributes.error || false : false;
            },

            Readonly: function () {
                return this.attributes ? this.attributes.readonly || false : false;
            },

            Disabled: function () {
                return this.attributes ? this.attributes.disabled || false : false;
            },

            Hidden: function () {
                return this.attributes ? this.attributes.hidden || false : false;
            },

            Approved: function () {
                if (this.attributes.approved != false) {
                    return true;
                }
                else {
                    return this.attributes ? this.attributes.approved || false : false;
                }

            },

            PlaceHolder: function () {
                if (this.column.mask == 'phone') {
                    return '(___) ___ __ __';
                }
                else if (this.column.mask == 'email') {
                    return 'name@companyname.com';
                }
                else {
                    return this.$root.$i18n.t(this.column.label);
                }
            },

            Label: function () {
                var requiredChar = '';
                if (this.column.validateMin != undefined && this.column.validateMin > 0) {
                    var requiredChar = '*';
                }
                return this.$root.$i18n.t(this.column.label) + requiredChar;
            },

            Hide: function () {
                var hide = this.attributes ? this.attributes.hide || '' : '';
                var hidden = hide.indexOf(this.state) != -1;
                return hidden;
            },

            Rules: function () {
                return this.rules;
            },
            ErrorMessage: function () {
                return this.attributes ? this.attributes.errormessages : '';
            },
            GetSelectOptions() {
                var result = [];
                Utilities.log("Get Select options:", this.column, "Value:", this.value)
                // Evet=1|Hayır=2
                // Evet|Hayır  => Evet=Evet|Hayır=Hayır
                var options = this.column.options;
                if (this.column.options == undefined) {
                    return result;
                }
                if (options.indexOf('[') == 0) {
                    //Object notation
                    Utilities.log('options object', options);
                    result = JSON.parse(options);
                } else if (typeof options == 'string') {
                    var eqIndex = options.indexOf('=');
                    var barIndex = options.indexOf('|');
                    if (eqIndex == -1 || eqIndex > barIndex) {
                        //Evet|Hayır formatında text ve value değerleri aynı

                        var parts = options.split('|');
                        for (var i = 0; i < parts.length; i++) {
                            result.push({ text: parts[i], value: parts[i] });
                        }
                    } else {
                        // Evet=1|Hayır=2 formatında
                        var parts = options.split('|');
                        for (var i = 0; i < parts.length; i++) {
                            var nvp = parts[i].split('=');
                            if ((nvp.length = 1)) result.push({ text: nvp[0], value: nvp[0] });
                            else result.push({ text: nvp[0], value: nvp[1] });
                        }
                    }
                } else result = options;
                Utilities.log(' select options:', result);
                return result;
            },
            GetSelectText() {
                if (this.value == null) {
                    return "";
                }
                var selectOptions = this.GetSelectOptions;
                var selectedValueList = this.value.toString().split(",");

                var text = "";

                for (var index in selectOptions) {
                    var option = selectOptions[index];
                    if (selectedValueList.indexOf(option.value) >= 0) {
                        text += option.text + ", ";
                    }
                }
                if (text.length > 2) {
                    text = text.substring(0, text.length - 2);
                }

                return text;
            },
            GetLocale: function () {
                return this.$store.getters.GetUserInfo.Locale || 'tr';
            }
        },
        methods: {
            GetMinMaxDate: function (date)
            {
                if (date == null || date == '')
                {
                    return '';
                }
                else
                {
                    return date;
                }
            },
            allowedDates: function (val)
            {
                var alloweddate = this.column.alloweddates;

                if (alloweddate == null || alloweddate == '')
                {
                    return true;
                }
                else
                {
                    return alloweddate.indexOf(val) !== -1;
                }
            },
            FieldButtonClicked: function (name) {
                console.log("FieldButtonClicked", name)
                this.$emit('FieldButtonClicked', { ButtonName: name, column: this.column });
            },
            HeaderButtonClicked(event, col) {
                event.col = col;
                this.$emit('HeaderButtonClicked', event);
            },
            RowButtonClicked(event, col) {
                event.col = col;
                this.$emit('RowButtonClicked', event);
            },

            ShowToolTip(event, on, what) {
                Utilities.log('ON tooltip:', on, event, what);
                if ((this.column || '') != '') {
                    if (what == 1) {
                        on.mouseenter();
                    } else on.mouseleave();
                }
            },
            DumpGrid(which, what, state) {
                Utilities.log(which, what, state);
            },
            ClearDate(event, column) {
                Utilities.log("ClearDate");
                this.value = "";
                this.UpdateValue(event,column);
            },
            OpenUrl: function () {
                window.open(this.value, '_blank');
            },
            OpenDocument: function () {
                if (this.value == "") return;
                var openUrl = '/api/showfile?file=' + this.value;
                window.open(openUrl, '_blank');
            },
            GetLink: function () {
                Utilities.log('Link clicked:', this.column);
                var url = this.column.link;

                var linkvalue = this.value;
                if (
                    typeof this.column.linkvalue != 'undefined' &&
                    this.column.linkvalue != ''
                ) {
                    linkvalue = this.GetFormField(linkvalue);
                }
                if (typeof url == 'undefined' || url == '') {
                    this.$emit('linkclicked', this.column);
                } else {
                    window.open(url + linkvalue, '_blank');
                }
            },
            GetFieldHeight: function (column) {
                var result = '50px';
                if (column.type == 'textarea') result = this.column.height + 'em';
                else if (column.type == 'image') result = '160px';
                //Utilities.log("GetFieldHeight for " + column.name + " type:" + column.type + " :" + result);
                return ''; //'height:' + result;
            },
            setdate: function (val, name) {
                //Utilities.log("setdate val:" + val + " name:" + name);
                this.dateopen = false;
            },
            SearchInputChanged: function (val, column) {
                //Utilities.log("SearchInputChanged:" + JSON.stringify(val));
                //if (typeof event == "undefined" || val == self.value) return;
                var payload = {};
                payload.search = val;
                payload.tableName = "";
                if (column.type == 'grid') {
                    payload.tableName = column.name;
                    payload.id = val.id;
                }
                Utilities.log('Emit autocomplete search:', payload);
                this.$emit('SearchInputChanged', payload);
            },

            SearchInputChangedDebounced: function (val) {
                // _.debounce(function(payload) {
                Utilities.log('SearchInputChanged1:', val);
                //if (typeof event == "undefined" || val == self.value) return;
                var payload = {};
                payload.search = val;
                this.$emit('SearchInputChanged', payload);
                // }, 500)
            },
            PrepareDocumentDialog(doc) {
                var payload = {
                    title: doc + 'Title',
                    content: doc + 'Content',
                    link: doc + 'Link'
                }
                this.$root.$emit('askAgreements', payload);
            },
            UpdateValue: function (event, column) {
                //payload
                //  column ( colum definition) => fieldname
                //  value ( updated value)
                //  tableName (formdata = form_data)
                //  id table içinde id
                //  selectedObject
                var val = this.value; // event.target.value;
                this.dateopen = false;
                var fieldname = column.name;
                var fieldType = column.type;
                if (column.mask && column.mask == 'phone') val = Utilities.CleanPhoneNumber(val);
                if (fieldType == 'number' || fieldType == 'int') {
                    var re = /,/gi;
                    var tstr = val.trim();
                    tstr = tstr.replace(re, '');
                    val = Number(tstr);
                }
                else if (fieldType == 'select') {
                    val = val.join(',')
                }

                Utilities.log(
                    'field updateValue val:' + val + ' column:',
                    column,
                    ' Event:',
                    event
                );
                var payload = {
                    fieldName: column.name,
                    fieldValue: val,
                    column: column,
                    tableName: '',
                    id: 0,
                    selectedObject: event
                };
                Utilities.log('Emit:', payload);
                this.$emit('change', payload);
            },

            UpdateValueAutoComplete: function (event, column) {
                //payload
                //  column ( colum definition) => fieldname
                //  value ( updated value)
                //  tableName (formdata = form_data)
                //  id table içinde id
                //  selectedObject
                var val = this.value; // event.target.value;
                var fieldname = column.name;
                Utilities.log('field UpdateValueAutoComplete val:', event, column);

                var itemList = this.attributes.items;
                var searchText = column.searchText;
                this.value = event.value ? event.value[searchText] : '';

                var payload = {
                    fieldName: column.name,
                    fieldValue: this.value,
                    column: column,
                    tableName: '',
                    id: 0,
                    selectedObject: event
                };
                this.$emit('change', payload);
            },

            UpdateValueMoney: function (event, column) {
                let fieldValue = event.fieldValue;
                let fieldName = event.fieldName;
                Utilities.log(
                    'Update Money val:' + fieldValue + ' Params:' + JSON.stringify(event)
                );

                var payload = {
                    fieldName: fieldName,
                    fieldValue: fieldValue,
                    selectedObject: event
                };

                this.$emit('change', payload);
            },

            UpdateValueFile: function (event) {
                Utilities.log('UpdateValueFile', event);
                this.$emit('change', event);
            },

            UpdateNote: function (event) {
                Utilities.log('UpdateNote', event);
                this.$emit('change', event);
            },

            UpdateSelect: function (event, column) {
                Utilities.log('UpdateSelect:', event + ' value:', this.value);
                if (this.value == event.value) return;
                var payload = {
                    fieldName: column.name,
                    fieldValue: event,
                    selectedObject: event
                };
                if (column.multiple) {
                    payload.fieldValue = event.join(',');
                }
                this.$emit('change', payload);
            },

            UpdateAutoComplete: function (event, column) {
                Utilities.log('UpdateSelect:', event + ' value:', this.value);
                if (this.value == event.value) return;
                var payload = {
                    fieldName: column.name,
                    fieldValue: event,
                    selectedObject: event
                };
                if (column.multiple) {
                    payload.fieldValue = event.join(',');
                }

                this.$emit('change', payload);
            },
            UpdateValueGrid: function (event,column) {
                Utilities.log('UpdateValueGrid', event);
                event.tableName = column;
                this.$emit('changeGrid', event);
            },
            changeSearchInputToTriggerFilter: function (event, column) {
                if (this.column.searchInput == '') {
                    this.column.searchInput = ' ';
                }
                else if (this.column.searchInput == ' ') {
                    this.column.searchInput = '  ';
                }
                else if (this.column.searchInput == '  ') {
                    this.column.searchInput = '   ';
                }
                else {
                    this.column.searchInput = ' ';
                }
            },

            UpdateCheckbox: function (event, column) {
                Utilities.log(
                    JSON.stringify(event) +
                    '---' +
                    JSON.stringify(this.value)
                );
                var val;
                if (this.value) val = 1;
                else val = 0;
                var fieldValue = val;
                var fieldName = column.name;
                //alert("Box state:" + fieldValue);
                var payload = {
                    fieldName: fieldName,
                    fieldValue: fieldValue,
                    selectedObject: event
                };

                this.$emit('change', payload);
            },
            queryForKeywords: function (event) {
                return true;
                //var tmpEvent = event || window.event;
                //var key = tmpEvent.keyCode || tmpEvent.which;

                //if (key === 8 || key === 9)
                //	return true;

                //if (key === 46 || key === 190) {
                //	if (this.value.indexOf('.') >= 0)
                //		event.preventDefault();

                //	return true;
                //}

                //if (key == 44) //,
                //	event.preventDefault();

                //if (key != 8 && key != 0 && (key < 48 || key > 57)) {
                //	event.preventDefault();
                //}
            },
            ButtonClick: function (column) {
                Utilities.log('Button Clicked', column);
                this.$emit('buttonclick', column);
            },
            ChangeAttribute: function (e) {
                Utilities.log("Field Emit changeAttribute", e)
                this.$emit("changeAttribute", e);
            },
            ShowGridAddRow: function (column) {
                return column.showaddrow && column.showaddrow == true
            },

        },
        created() {
            this.value = this.invalue;
            this.currency = this.doviz;
        },
        mounted() {
            Utilities.log('FormField Mounted ', this.column, this.invalue);
            if (this.column.type == 'checkbox' || this.column.type == 'confirmbox') {
                this.value = this.invalue ? 1 : 0;
            }
            else if (this.column.type == 'select') {
                if (this.column.multiple) {
                    var temp = this.invalue || '0';
                    this.value = temp.split(',');
                } else this.value = this.invalue || '0';
            }
            else if (this.column.type == 'date') {
                var temp = this.invalue || '';
                this.value = temp.split('T')[0];
            }
            else if (this.column.type == 'textarea' || this.column.type == 'grid') {
                this.value = this.invalue;
                //Utilities.log('Textarea ', this.column, this.value);
            }
            else if (this.column.type == 'number') {
                var formatedString = this.invalue == null ? '' : this.invalue; // this.invalue.toLocaleString(this.GetLocale);
                var parts = formatedString.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.value = parts.join(".");
                Utilities.log('MOUNTED NUMBER FORMATED', this.column, this.invalue, formatedString);
                //this.value = formatedString;
            }
            else if (this.column.type == 'int') {
                var formatedString = this.invalue == null ? '' : this.invalue.toString();
            }
            else {
                this.value = this.invalue;
                if (this.column.type == 'money') this.currency = this.doviz || 'TL';
            }

            Utilities.log('FORMATED', this.column.name, this.invalue, this.value);
        },

        beforeUpdate() {
            // this.state = this.$store.getters.GetFormState;
            // this.value = this.invalue;
            // this.currency = this.doviz || "TL";
            // Utilities.log(
            //   "---beforeUpdate   " +
            //     this.column.name +
            //     " val:" +
            //     this.value +
            //     " Type:" +
            //     typeof this.value +
            //     " currency:" +
            //     this.currency
            // );
        },

        watch: {
            //GetSelectOptions: function (nval, oval)
            //{
            //    console.log("yeni biri " + nval);
            //},

            value: function (nval, oval) {
                if (this.column.mask && this.column.mask == 'phone') {
                    var formated = Utilities.FormatPhoneNumber(nval);
                    if (nval != formated) this.value = formated;
                }
            },

            invalue: function (val, old) {
                // Utilities.log(
                // 	'Watch:' + this.column.name + ' Val:' + val + ' Old:' + old,
                // 	' Column:',
                // 	this.column
                // );
                if (this.column.type == 'checkbox' || this.column.type == 'confirmbox') {
                    this.value = this.invalue ? 1 : 0;
                } else if (this.column.type == 'auto') {
                    //this.search = this.invalue;
                    this.value = val;
                } else if (this.column.type == 'date') {
                    var temp = this.invalue || '';
                    this.value = temp.split('T')[0];
                    var locale = this.GetLocale;
                    if (val == null || typeof val == "undefined") {
                        val = "";
                    }
                    else {
                        var parts = val.split('-');
                        if (locale == 'tr-TR') {
                            val = parts[0] + '/' + parts[1] + '/' + parts[2];
                        } else {
                            val = parts[0] + '/' + parts[2] + '/' + parts[1];
                        }
                    }
                } else if (this.column.type == 'select') {
                    if (this.column.multiple) {
                        var temp = val || '';
                        this.value = temp.split(',');
                    } else this.value = this.invalue;
                }
                else if (this.column.type == 'autocomplete') {
                    if (this.column.multiple) {
                        var temp = val || '';
                        this.value = temp.split(',');
                    } else { this.value = this.invalue };
                }
                else if (this.column.type == 'number' || this.column.type == 'money') {
                    var locale = this.GetLocale;
                    var formatedString = val == null ? '' : val; //val.toLocaleString(locale);
                    var parts = formatedString.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.value = parts.join(".");

                    //this.value = formatedString;
                    Utilities.log('NUMBER FORMATED', this.column, this.val, formatedString);
                }
                else if (this.column.type == 'int') {
                    var formatedString = val == null ? '' : this.invalue.toString();
                    this.value = formatedString;
                }
                else if (this.column.type == 'history') {
                    this.value = this.invalue;
                }
                else {
                    this.value = this.invalue;
                    this.currency = this.doviz || 'TL';
                }
            },
            doviz: function (val, old) {
                //Utilities.log('Watch:' + this.column.name + ' Val:' + val + ' Old:' + old);
                this.currency = val;
            },

            state: function (val, old) {
                //Utilities.log('Watch: State  Val:' + val + ' Old:' + old);
                this.state = val;
            },

            attributes: function (value, old_value) {
                Utilities.log('watch attributes in Field:', value);
            }
        },
        filters: {
            FormatDateTR: function (value, undefinedtext, indefinite) {
                Utilities.log("Format datetr:", value);
                var result = '';
                if (!value) {
                    return undefinedtext;
                }
                var temp = value || '';
                var value = temp.split('T')[0]; // yyyy-mm-ddThh:mm:ss: formatını yyy-mm-dd olarak truncate et
                var parts = value.split('-');
                if ((parts.length = 3)) {
                    result = parts[2] + '/' + parts[1] + '/' + parts[0];
                }
                if (value == '9999-12-31') {
                    result = indefinite;
                }
                Utilities.log("format result:", result)
                return result;
            },
            FormatDateTimeTR: function (value, column, undefinedtext, indefinite) {
                Utilities.log("Format datetr:", value);
                var result = '';
                if (!value) {
                    return undefinedtext;
                }
                var temp = value || '';
                var temp = temp.split('T')[0]; // yyyy-mm-ddThh:mm:ss: formatını yyy-mm-dd olarak truncate et
               
                if (temp == '9999-12-31') {
                    result = indefinite;
                }

                var format = 'DD/MM/YYYY HH:mm';
                if (typeof column.format != 'undefined')
                    format = column.format;

                result = moment(value)
                    .format(format);
                Utilities.log("format result:", result)
                return result;
            },
            FormatColumn: function (value, column, locale, undefinedtext, select) {
                //Utilities.log('FORMAT FILTER', column, value, locale);
                var name = column.name;
                var result = value;
                switch (column.type) {
                    case 'text':
                    case 'textarea':
                    case 'auto':
                        break;
                    case 'number':
                    case 'money':
                        {
                            var formatedString = value == null ? '' : value;
                            var parts = formatedString.toString().split(".");
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            result = parts.join(".");
                            break;
                        }
                    case 'int':
                        var formatedString =
                            value == null ? '' : value; // value.toLocaleString(locale);
                        //Utilities.log('NUMBER FORMATED', column, value, formatedString);
                        result = formatedString;
                        //result = numeral(value).format(column.format);
                        break;
                    //case 'int':
                    //    result = numeral(value).format('0');
                    //    break;
                    case 'date':
                        if (!value || value == null) {
                            result = '';
                            break;
                        }
                        var d = moment(value.split('T')[0]);
                        if (typeof column.format == 'undefined') column.format = 'DD/MM/YYYY';
                        //Utilities.log('date convert:', value, column.format, temp, d)
                        result = moment(value.split('T')[0])
                            .format(column.format)
                            .split('T')[0];
                        break;
                    case 'select':
                        var item = column.options.find(function (ext) {
                            return ext.value == value;
                        });
                        result = item ? item.text : select;
                        break;
                    //case 'checkbox':
                    //    var optionList = column.options.split('|');
                    //    if (optionList.length == 2)
                    //        result = value ? optionList[0] : optionList[1];
                    //    break;
                    case 'file':
                        if (!value || value == null) {
                            result = '';
                            break;
                        }
                        var parts = value.split('__');
                        if (parts.length >= 2) result = value.replace(parts[0] + '__', '');
                        else result = value;
                        //result = '';
                        break;
                    default:
                        break;
                }
                //Utilities.log('filter result:', column.name, ' value', result);
                if (result == '' || result == null) {
                    result = undefinedtext;
                }
                return result;
            }
        }
    };
</script>
<style>
    .height-issue-fix > div > .v-input__slot {
        min-height: 100% !important;
        max-height: 100%;
        margin-bottom: 0.2em;
    }

    .height-issue-fix fieldset {
        top: 0 !important;
        height: inherit;
    }

        .height-issue-fix fieldset legend {
            display: none;
        }

    .height-issue-fix .v-text-field__slot {
        height: inherit;
    }

    .height-issue-fix .v-input__append-inner {
        height: inherit;
        align-items: center;
        align-self: unset !important;
    }

    .fielderror {
        border-color: red !important;
    }

    .gridError {
        border: 2px solid red !important;
    }

    .fieldDisabled {
        background-color: lightgray;
    }

    .kforms-textarea {
        line-height: 25px !important;
    }

    .urlfield:hover {
        cursor: pointer;
        color: blue;
    }

    .v-select__slot .v-select__selections input {
        padding: 10px 0 10px !important;
    }

    .unspecified {
        color: rgba(128, 128, 128, 82%);
    }
    .v-input--selection-controls.v-input--checkbox {
        justify-items: center;
        align-items: center;
    }


        .v-input--selection-controls.v-input--checkbox .v-input__prepend-outer {
            margin-bottom: 22px;
        }


</style>
