<template>
    <v-dialog v-model="showDialog" width="800" scrollable>
        <v-card class="pa-5">
            <v-card-title>
                <span class="text-h5">{{$t(title)}}</span>
                <v-spacer></v-spacer>
                <v-btn icon v-on:click="CloseDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <br />
            <v-card-text style="height: 600px" v-html="$t(content)">

            </v-card-text>
            <v-card-actions>
                <v-btn text block tile v-on:click="DownloadDocument()" color="primary" class="text-capitalize px-5">
                    <span class="caption">{{$t('AL_Download')}}</span><v-icon small right>mdi-download</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import axios from 'axios';  //TODO:Kullanılmıyor
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import Utilities from '@/Utilities.js'

    import Vue from 'vue';
    import alertIcon from './alertIcon'; //TODO:Kullanılmıyor
    Vue.component('alertIcon', alertIcon);


    export default {
        name: 'terms',
        props: {
            message: Object
        },
        components: {
        },
        data() {
            return {
                showDialog: false,
                OnCloseDialog: undefined,
                OnApprove: undefined,
                link: '',
                title: '',
                content: '',
            };
        },
        mixins: [FormMixIn],
        mounted() {
            this.$root.$on('askAgreements', (data) => {
                console.log("askAgreements ===> ", data);
                this.title = data.title;
                this.content = data.content;
                this.link = this.$root.$i18n.t(data.link);
                this.showDialog = true;
            });
        },

        methods: {
            CloseDialog() {
                Utilities.log('Close message dialog');
                if (this.OnCloseDialog != undefined) {
                    this.OnCloseDialog();
                }
                this.showDialog = false;
            },
            DownloadDocument() {
                var url = this.link;
                window.open(url);
            }
        },
        watch: {
            showDialog: function (newValue, oldValue) {
                console.log('New: ', newValue, '  Old', oldValue);
                Utilities.log('New: ', newValue, '  Old', oldValue);
            }
        }
    };
</script>
