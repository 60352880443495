import Vue from 'vue'
import Router from 'vue-router'
import { escape } from 'querystring';
import store from './store'
import Utilities from '@/Utilities.js'
import { i18n, loadLanguageAsync } from '@/plugins/i18n'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            redirect: '/Anasayfa',
        },
        {
            path: '/Account',
            props: {
                form: 'Login'
            },
            component: () => import('./layouts/LoginLayout.vue'),
            children: [
                {
                    path: 'Login',
                    name: 'login',
                    component: () => import('./views/Account/Login.vue'),
                },
                {
                    path: 'Forgot',
                    name: 'forgot',
                    component: () => import('./views/Account/Forgot.vue'),
                },
                {
                    path: 'ResetPassword/:code',
                    name: 'resetpassword',
                    component: () => import('./views/Account/ResetPassword.vue'),
                },
                {
                    path: 'Register',
                    name: 'register',
                    component: () => import('./views/Account/Register.vue'),
                },
                {
                    path: 'KayitTalebi',
                    name: 'kayittalebi',
                    component: () => import('./views/Account/kayittalebi.vue'),
                }
            ]
        },
        {
            path: '/Anasayfa',
            props: {
                form: 'home'
            },
            component: () => import('./layouts/pagelayout.vue'),
            children: [
                {
                    path: '',
                    name: 'home',
                    component: () => import('./views/Home.vue'),
                }]
        },

        {
            path: '/Sertifikalar',
            props: {
                form: 'sertifika'
            },
            component: () => import('./layouts/formlayout.vue'),
            children: [
                {
                    path: '',
                    name: 'sertifikaview',
                    component: () => import('./views/sertifika.vue'),
                }]
        },
        {
            path: '/Degerlendirmelerim',
            props: {
                form: 'tedarikcidegerlendirme'
            },
            component: () => import('./layouts/formlayout.vue'),
            children: [
                {
                    path: '',
                    name: 'tedarikcidegerlendirmeview',
                    component: () => import('./views/Forms/degerlendirme.vue'),
                },
                {
                    path: ':id',
                    name: 'tedarikcidegerlendirmeform',
                    component: () => import('./views/Forms/degerlendirme.vue'),
                }
            ]
        },
        {
            path: '/Kullanicilarim',
            props: {
                form: 'users'
            },
            component: () => import('./layouts/formlayout.vue'),
            children: [
                {
                    path: '',
                    name: 'users',
                    component: () => import('./views/Users.vue'),
                }]
        },
        {
            path: '/changePassword',
            props: {
                form: 'changePassword'
            },
            component: () => import('./layouts/formlayout.vue'),
            children: [
                {
                    path: '',
                    name: 'changePassword',
                    component: () => import('./views/ChangePassword.vue'),
                }]
        },
        {
            path: '/Bilgilerim',
            props: {
                form: 'tedarik'
            },
            component: () => import('./layouts/pagelayout.vue'),
            children: [
                {
                    path: '',
                    name: 'tedarik',
                    component: () => import('./views/bilgilerim.vue'),
                }]
        },
        {
            path: '/SiparisKalemler',
            props: {
                form: 'sipariskalemler'
            },
            component: () => import('./layouts/pagelayout.vue'),
            children: [
                {
                    path: '',
                    name: 'sipariskalemler',
                    component: () => import('./views/Pages/sipariskalemler.vue'),
                }]
        },
        {
            path: '/IrsaliyeKalemler',
            props: {
                form: 'faturaolustur'
            },
            component: () => import('./layouts/pagelayout.vue'),
            children: [
                {
                    path: '',
                    name: 'irsaliyekalemler',
                    component: () => import('./views/Pages/faturaolustur.vue'),
                }]
        },
        {
            path: '/Irsaliyeler',
            props: {
                form: 'irsaliye'
            },
            component: () => import('./layouts/formlayout.vue'),
            children: [
                {
                    path: '',
                    name: 'irsaliyeview',
                    component: () => import('./views/Forms/irsaliye.vue'),
                },
                {
                    path: ':id',
                    name: 'irsaliyeform',
                    component: () => import('./views/Forms/irsaliye.vue'),
                }
            ]
        },
        {
            path: '/Sozlesmeler',
            props: {
                form: 'sozlesme'
            },
            component: () => import('./layouts/formlayout.vue'),
            children: [
                {
                    path: '',
                    name: 'sozlesmeview',
                    component: () => import('./views/Forms/sozlesme.vue'),
                },
                {
                    path: ':id',
                    name: 'sozlesmeform',
                    component: () => import('./views/Forms/sozlesme.vue'),
                }]
        },
        {
            path: '/Siparisler',
            props: {
                form: 'siparis'
            },
            component: () => import('./layouts/formlayout.vue'),
            children: [
                {
                    path: '',
                    name: 'siparisview',
                    component: () => import('./views/Forms/siparis.vue'),
                },
                {
                    path: ':id',
                    name: 'siparisform',
                    component: () => import('./views/Forms/siparis.vue'),
                }]
        },
        {
            path: '/Faturalar',
            props: {
                form: 'fatura'
            },
            component: () => import('./layouts/formlayout.vue'),
            children: [
                {
                    path: '',
                    name: 'faturaview',
                    component: () => import('./views/Forms/fatura.vue'),
                },
                {
                    path: ':id',
                    name: 'faturaform',
                    component: () => import('./views/Forms/fatura.vue'),
                },
                {
                    path: ':view',
                    name: 'faturaviewIn',
                    component: () => import('./views/Forms/fatura.vue'),
                }]
        },
        {
            path: '/gelenteklif',
            props: {
                form: 'gelenteklif'
            },
            component: () => import('./layouts/formlayout.vue'),
            children: [
                {
                    path: '',
                    name: 'gelenteklifview',
                    component: () => import('./views/Forms/gelenteklif.vue'),
                },
                {
                    path: ':id',
                    name: 'gelenteklifform',
                    component: () => import('./views/Forms/gelenteklif.vue'),
                }]
        },


        {
            path: '/test',
            props: {
                form: 'Test'
            },
            component: () => import('./views/test.vue'),
        },
        {
            path: '/test/about',
            props: {
                form: 'About'
            },
            component: () => import('./layouts/pagelayout.vue'),
            children: [
                {
                    path: '',
                    component: () => import('./views/About.vue'),
                }]
        },
        {
            path: '*',
            props: {
                form: 'home'
            },
            component: () => import('./layouts/pagelayout.vue'),
            children: [
                {
                    path: '',
                    component: () => import('./views/Home.vue'),
                }]
        },

        //ListTeknoserErrors.vue

    ],

})

router.beforeEach((to, from, next) => {
    var lang = Utilities.GetCurrentLanguage();
    loadLanguageAsync(lang).then(() => next())
});

export default router;