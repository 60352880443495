<template>
    <v-container fluid v-if="data.length != 0">
        <grid :columns="GetColumns()"
              :data="GetDataItems()"
              :header="$t('TH_FileHistory')"
              @rowclicked="RowClicked($event)"
              @RowButtonClicked="RowButtonClicked($event)"
              :attributes="GetFileHistoryAttributes"
              addrow=""
              :showExportToExcelButton="false"
              :clickrow="1 == 1" />
    </v-container>
    <div v-else>
        {{ $t('IM_NoRowsAtTable')}}
    </div>
</template>

<script>

    import Utilities from '@/Utilities.js'
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);

    var fileHistory = {
        tableName: 'fileHistory',
        label: 'TH_FileHistory',
        columns: {
            tarih: {
                type: 'date',
                name: 'tarih',
                label: 'FL_Date',
                edit: '',
                width: 30,
            },
            fieldname: {
                type: 'text',
                name: 'fieldname',
                label: 'FL_Fieldname',
                edit: '',
                width: 30,
            },
            url: {
                type: 'file',
                label: 'FL_Bid',
                name: 'url',
                edit: '',
                width: 40,
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    import { mapGetters } from 'vuex';
    import grid from '@/components/grid.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';

    export default {
        name: 'fileHistory',
        mixins: [FormMixIn],
        components: {
            grid
        },
        props: {
        },
        data: function () {
            return {
                data: []
            };
        },
        methods: {
            GetColumns: function () {
                return fileHistory.columns;
            },
            GetDataItems: function () {
                return this.data;
            },
            FormLoaded: function ()
            {
                return;
            },

            Selected: function ()
            {
                return;
            },

            FormButtonClicked(col) {
                alert(col);
                var name = col.name;

            },

            RowClicked: function (rowdata, table)
            {
                return;

            },

            RowButtonClicked: function (col)
            {
                return;
                
            },

            ChangeAttribute: function (attribute, tableName)
            {
                return;
            },
            LoadData: function () {
                var payload = {
                    queryName: 'GetFileHistory',
                    parameters: { form: this.GetState.form, formId: this.GetState.formid}
                };
                var self = this;
                this.$store.dispatch('GetLovDynamic', payload).then(response => {
                    self.data = response.data;
                });
            },
        },

        mounted: function () {
            this.LoadData();
        },
        computed: {
            DynamicFormInfo: function () {
                return dynamic_formInfo;
            },
            GetFileHistoryAttributes: function () {
                return fileHistory.attributes;
            },
        },
        watch: {
            parameters: {
                handler: function (val, oldVal) {
                    var a = val.showFormDialog;
                    this.modalFormId = val.modalFormId;
                },
                deep: true
            },
            modalFormId: function (nval, oldval) {
                if (nval == 0) {
                    this.CreateNewForm();
                }
                else if (nval == -1) {

                }
                else {
                    this.LoadUserForm(nval);
                }
            }
        }
    };
</script>
