<template>
	<v-app>
		<router-view :single="single" />
	</v-app>
</template>
<script>
	import Vue from 'vue';
	import Vuex from 'vuex';

	import { mapGetters } from 'vuex';
	import dynoform from '@/components/DynoForm.vue';
	import grid from '@/components/grid.vue';
	import leftmenu from '@/components/leftmenu.vue';
	import kview from '@/components/view.vue';
	import axios from 'axios';
	import _ from 'lodash';
	import 'material-design-icons-iconfont/dist/material-design-icons.css';
	import { FormMixIn } from '@/MixIns/FormMixins';
	import store from '@/store';
	import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

	import 'bootstrap/dist/css/bootstrap.css'
	import 'bootstrap-vue/dist/bootstrap-vue.css'

	import moment from 'moment';
	import Utilities from '@/Utilities.js'

	import showactionresult from '@/components/ShowActionResult.vue';
	Vue.component('showactionresult', showactionresult);

    import showdialog from '@/components/ShowDialog.vue';
	Vue.component('showdialog', showdialog);

    import terms from '@/components/AgreementDialog.vue';
    Vue.component('terms', terms);

	export default {
		name: 'portal',
		mixins: [FormMixIn],
		components: {
			dynoform,
			grid,
			leftmenu,
			kview
		},
		props: {
			//form: String,
			formid: Number,
			viewname: String,
			message: Object
		},
		data: function ()
		{
			return {
				drawer: null,
				valid: false,
				messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
				lazy: true,
				username: '',
				viewName: '',
				form: '',
				validateError: '',
				single: false,
			};
		},
		
		mounted: function ()
		{
			var self = this;
			setInterval(function () { self.PingServer() }, 300000);
			Utilities.log('Mounted Route APP default Mode full', this.single);
			//if (!this.IsAuthPage(this.$route.name) && !this.$store.getters.isUserAuthenticated) {
   //             this.$router.push({ name: 'login', });
   //         }
   //         else if (!this.IsAuthPage(this.$route.name) && this.IsApprovedPage(this.$route.name) && !this.GetUserInfo.approved) {
   //             this.$router.push({ name: 'tedarik' });
   //         }
			var query = this.$route.query;
			if (_.isEmpty(query))
			{
				// Simple route. Show default view ( "tumu" )
				Utilities.log("MountedCheck NoQuerry ", query);
				this.single = false;
			}
			else
			{
				Utilities.log("MountedCheck single page APP ", query);
				if (typeof query.show != "undefined")
				{

					var showSingle = query.show == 'single';
					Utilities.log("Show single page APP ", query, showSingle);
					this.single = showSingle;

				}
				else
				{

					Utilities.log("Show full page APP", query);
					this.single = false;
				}
			}
			Utilities.log('Mounted Route APP END Mode full', this.single);
		},
		methods:
		{
			PingServer()
			{
				this.$store.dispatch('PingServer');
			},
		},
		watch:
		{
			$route: function (nval, oval)
			{
				if (!this.IsAuthPage(nval.name) && !this.$store.getters.isUserAuthenticated) {
                   this.$router.push({ name: 'login',});
				}
				else if (!this.IsAuthPage(nval.name) && this.IsApprovedPage(nval.name) && nval.name && !this.GetUserInfo.approved) {
                    this.$router.push({ name: 'tedarik' });
                }
				Utilities.log('WATCH Route App:', nval, oval);
				var query = this.$route.query;
				if (_.isEmpty(query))
				{
					// Simple route. Show default view ( "tumu" )
					Utilities.log('WATCH Route APP default Mode full');
					this.single = false;
				}
				else
				{
					Utilities.log("Check single page APP ", query);
					if (typeof query.show != "undefined")
					{
						var showSingle = query.show == 'single';
						Utilities.log("Show single page APP ", query, showSingle);
						this.single = showSingle;
					}
					else
					{
						Utilities.log("Show full page APP", query);
						this.single = false;
					}
				}
				Utilities.log('WATCH APP end single:', this.single);
			}
		}
	};
</script>
