
var Utilities = {


    /*
     * Console.log replacement
     */
    log: function (...parameters) {
        if (process.env.NODE_ENV === 'development') {
            var caller = "";
            try {
                var stack = new Error().stack;
                //console.log("stack1:" + stack);
                var stacklis = stack.split('\n');
                var line = stacklis[2];
                var rx = /(webpack-internal:[^)]*)/g;
                var arr = rx.exec(line);

                if (arr.length >= 2) {
                    caller = arr[1];
                }
            } catch (e) {

            }
            console.log(...parameters, "Caller:", caller);
        }
    },
    /*
     * Returns 1 if the IBAN is valid 
     * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
     * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
     */
    isValidIBANNumber: function (input) {
        var CODE_LENGTHS = {
            AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
            CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
            FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
            HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
            LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
            MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
            RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
            AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
            SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
        };
        var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
            code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
            digits;
        // check syntax and length
        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
            return false;
        }
        // rearrange country code and check digits, and convert chars to ints
        digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
            return letter.charCodeAt(0) - 55;
        });
        // final check
        return this.mod97(digits);
    },

    mod97: function mod97(string) {
        var checksum = string.slice(0, 2), fragment;
        for (var offset = 2; offset < string.length; offset += 7) {
            fragment = String(checksum) + string.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }
        return checksum;
    },



    // Phone Number formating --- Not complete yet
    FormatPhoneNumber: function (val) {
        //normalize string and remove all unnecessary characters
        if (val == null || val.length < 4) return val;
        var phone = val.replace(/\D/g, '');

        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            phone = `(${match[1]})${match[2] ? ' ' : ''}${match[2]}${match[3] ? ' ' : ''}${match[3]}`;
        }
        return phone;
    },
    CleanPhoneNumber: function (val) {
        //normalize string and remove all unnecessary characters        
        var phone = val.replace(/\D/g, '');
        return phone
    },
    FormatInternationalPhoneNumber: function (val) {
        var countryCode = '';
        if (val.substr(0, 2) == '00') val = '+' + val.substr(2);
        if (val.substr(0, 1) != '+') return val;
        val = val.substr(1);
        for (var i = 1; i <= 3; i++) {
            var checkCode = val.substr(0, i);
            for (var j = 0; j < CountryCodes.length; j++) {
                if (checkCode == CountryCodes[j]) {
                    countryCode = CountryCodes[j];
                    break;
                }
            }
            if (countryCode != '') {
                var nationalCode = val.substr(i);
                var formated = this.FormatPhoneNumber(nationalCode);
                return '+' + countryCode + ' ' + formated;
            }
        }
        return val;
    },
    CountryCodes: ['1', '7', '20', '27', '30', '31', '32', '33', '34', '36', '39', '40', '41', '43', '44', '45', '46', '47', '48', '49', '51',
        '52', '53', '54', '55', '56', '57', '58', '60', '61', '62', '63', '64', '65', '66', '81', '82', '84', '86', '90', '91', '92',
        '93', '94', '95', '98', '211', '212', '213', '216', '218', '220', '221', '222', '223', '224', '225', '226', '227', '228', '229', '230', '231',
        '232', '233', '234', '235', '236', '237', '238', '239', '240', '241', '242', '243', '244', '245', '246', '247', '248', '249', '250', '251', '252',
        '253', '254', '255', '256', '257', '258', '260', '261', '262', '263', '264', '265', '266', '267', '268', '269', '290', '291', '297', '298', '299',
        '350', '351', '352', '353', '354', '355', '356', '357', '358', '359', '370', '371', '372', '373', '374', '375', '376', '377', '378', '380', '381',
        '382', '383', '385', '386', '387', '389', '420', '421', '423', '500', '501', '502', '503', '504', '505', '506', '507', '508', '509', '590', '591',
        '592', '593', '594', '595', '596', '597', '598', '599', '670', '672', '673', '674', '675', '676', '677', '678', '679', '680', '681', '682', '683',
        '685', '686', '687', '688', '689', '690', '691', '692', '850', '852', '853', '855', '856', '880', '886', '960', '961', '962', '963', '964', '965',
        '966', '967', '968', '970', '971', '972', '973', '974', '975', '976', '977', '992', '993', '994', '995', '996', '998'],
    GetCurrentLanguage: function () {
        var lang = this.GetCookie("currentLanguage");
        if (lang == null || (lang != 'tr' && lang != 'en')) {
            lang = 'tr';
            this.SetCurrentLanguage(lang);
        }
        return lang;
    },
    SetCurrentLanguage: function (lang) {
        return this.SetCookie("currentLanguage", lang);
    },
    DefaultCookieDayCount: 30,
    GetCookie: function (name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    SetCookie: function (name, value) {
        var expires = "";
        var date = new Date();
        date.setTime(date.getTime() + (this.DefaultCookieDayCount * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    RemoveCookie: function (key) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    GetRandomNumber: function (input) {
        var result = 0;
        if (window.crypto) {
            var array = new Uint32Array(1);
            window.crypto.getRandomValues(array);

            var result = (array[0] * 1.0) / 4294967295.0;
        }
        else {
            var tick = new Date().getTime();
            var randfull = tick % (256 * 256) + tick % 256;;
            result = randfull / (256 * 256);
            if (result > 1) result -= 1;            
        }
        return result;
        
    }
}
export default Utilities;
