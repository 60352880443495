import tr from 'vuetify/es5/locale/tr'
export default {
    $vuetify: {
        ...tr,
        dataFooter: {
            ...tr.dataFooter,
            itemsPerPageText: 'Satır/Sayfa',
            itemsPerPageAll: 'Tümü'
        }
    }
}
