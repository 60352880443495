<template>
    <div>
        <v-list>
            <v-list-item @click="BackToHomePage()" style="text-decoration: none; color: inherit;">
                <v-list-item-action>
                    <v-icon class="primary--text">mdi-arrow-left</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title class="primary--text">
                        {{ $t("RL_Homepage") }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list>
            <v-list-item-group>
                <v-list-item v-for="item in itemList"
                             :key="Math.floor(Math.random() * 1000000)"
                             @click="linkClicked(item)">
                    <v-list-item-action>
                        <v-icon>chevron_right</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t(item.linktitle) }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </div>
</template>
<script>
    import { FormMixIn } from '@/MixIns/FormMixins';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import Utilities from '@/Utilities.js'

    export default {
        name: 'leftmenu',
        props: {
            form: String
        },

        data() {
            return {
                itemList: {}
            };
        },
        mixins: [FormMixIn],
        mounted() {
            Utilities.log('leftmenu mounted ', this.form);
            var parameters = {
                form: this.form
            };
            // Buray� store i�inde ortak noktadan g�nder
            var self = this;
            var postData = new Object();
            postData.payload = {};
            postData.payload.queryName = 'GetFormNavigation';
            postData.payload.search = '';
            postData.payload.parameters = parameters;
            postData.url = this.GetHostUrl + 'api/ServerQuery';
            postData.success = response => {
                try {
                    Utilities.log("Got nav:", response)
                    var resultCode = response.data.errorCode;
                    Utilities.log('ResultCode:' + resultCode);
                    if (resultCode != 0) {
                        Utilities.log('Fetch Navigation error:' + response.data.resultText);
                        if (resultCode == 401) {
                            this.ShowDialog(response.data.errorMessage, 'E', 'dialog');
                            this.$router.push({ name: 'login' });
                        }
                        return;
                    }
                    self.itemList = response.data.list;
                } catch (e) {
                    Utilities.log('Fetch Navigation error2 ' + e);
                }
            };
            this.$store.dispatch('FetchDataFromServer', postData);
            //Utilities.log("End leftmenu mounted");
        },
        methods: {
            linkClicked(item)
            {
                Utilities.log('Left Menu linkClicked Emit', item, 'State:', this.GetState);
                this.$emit('menuclicked', item);
            },
            BackToHomePage() {
                this.$store.commit("UpdateViewName", { viewName: "" });
                this.$router.push("/");
            }
        },
        watch: {}
    };
</script>
