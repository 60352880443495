<template>
    <div class="history">
        <v-row v-if="editableForward && canSelectApprover">
                <v-col cols="6">
                    <autocomplete outlined
                                  v-model="oonayad"
                                  :invalue="oonayad"
                                  :isLoading="false"
                                  :itemtext="autoCompleteProperties.searchText"
                                  :itemvalue="autoCompleteProperties.searchText"
                                  :minLength="autoCompleteProperties.minLength"
                                  :class="{fielderror:error}"
                                  :error="error"
                                  class="my-1"
                                  :label="$t('FL_ForwardName')"
                                  :params="autoCompleteProperties"
                                  :handleChangeAndSearchInComponent="true"
                                  @change="oonayadChanged($event)">
                    </autocomplete>
                </v-col>
                <v-col cols="6">
                    <div class="caption font-weight-thin">
                        {{ $t('FL_ForwardTitle') }}
                    </div>
                    <div :class="{'body-2': (oonayTitle == ''),'body-1': (oonayTitle != ''), 'font-weight-thin': (oonayTitle == ''), 'unspecified': (oonayTitle == ''), 'font-italic': (oonayTitle == '')  }">
                        {{ oonayTitle | FormatColumn(column, $t('ST_Undefined')) }}
                    </div>
                </v-col>
        </v-row>
        <v-row v-if="editable">
            <v-col cols="12">
                <v-textarea outlined hide-details
                            @change="NoteChanged($event)"
                            v-model="value"
                            :class="{fielderror:error}"
                            :error="error"
                            :value="value"
                            :label="$t('FL_Note')"
                            :placeholder="$t('FL_Note')">
                </v-textarea>
            </v-col>
        </v-row>
        <v-row v-if="oonaynots && oonaynots.length > 0">
            <v-col>
                <v-alert border="left"
                         color="secondary darken-3"
                         text
                         dense>
                    <div class="text-h8">
                        {{$t("FL_Notes")}}
                    </div>
                    <span v-for="note in oonaynots" v-html="note.history" />
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <v-alert border="left"
                         color="secondary darken-3"
                         text
                         dense>
                    <div class="text-h8">
                        {{$t("FL_Notes")}}
                    </div>
                    <span>{{$t("IM_NotesEmpty")}}</span>
                </v-alert>
            </v-col>
        </v-row>
    </div>
</template>
<style>
    .history span {
        font-size: 12px;
    }
    .fielderror {
        border-color: red !important;
    }
</style>
<script>

    var autoCompleteProperties = {
        type: 'auto',
        group: 1,
        sira: 40,
        col: '6',
        name: 'oonayad',
        label: 'FL_ForwardName',
        edit: '2',
        parameters: [],
        outputs: {
            Sicil: 'oonaysicilno',
        },
        itemText: ['adsoyad'],
        searchText: 'adsoyad',
        validateMin: 1,
        minLength: 0,
        queryName: 'GetUsers',
        hide: '01Z',
    };
    import Utilities from '@/Utilities.js';
    import { FormMixIn } from '@/MixIns/FormMixins';

    export default {
        name: "history",
        data: function () {
            return {
                survey: [],
                openallpanel: [],
                oonaynots: [],
                value: '',
                oonayad: '',
                oonaysicilno: '',
                oonayTitle: '',
                canSelectApprover: true
            };
        },
        mixins: [FormMixIn],
        props: {
            params: Object,
            invalue: String,
            editable: Boolean,
            approved: Boolean,
            label: String,
            column: Object,
            error: Boolean,
            isPortal: { type: Boolean, default: false },
        },
        computed:
        {
            autoCompleteProperties: function () {
                return autoCompleteProperties;
            },
            editableForward: function () {
                var edit = this.column.forwardEdit || '';
                var st = this.GetFormState;
                if (typeof st == 'undefined' || st == null || st == '') st = '0';
                var editable = edit.indexOf(st) != -1;
                //Utilities.log("Editable=", edit," state", this.state, "column:", this.column);
                return editable;
            }
        },
        mounted: function () {
            var self = this;
            this.$store.dispatch('GetOnayExt', this.isPortal)
                .then(response => {
                    Utilities.log("GetOnayExt  success result:", response);
                    self.oonaynots = response.data.data;
                    self.canSelectApprover = response.data.canSelectApprover;
                })
                .catch(err => {
                    Utilities.log("GetOnayExt error result:", err);
                });
        },

        methods:
        {
            NoteChanged: function (event) {
                Utilities.log("note change:" + this.column)
                var val = this.value;
                var field = this.column.name;
                var payload = { fieldName: field, fieldValue: val };
                this.$emit("change", payload);
            },
            oonayadChanged: function (event)
            {
                console.info('oonayadChangedoonayadChangedoonayadChanged');

                if (event == undefined || event.value == undefined) {
                    event = { value: { Unvan: '', Sicil: '' } };
                }

                this.oonayTitle = event.value.Unvan;
                this.oonaysicilno = event.value.Sicil;

                let payload = {};
                payload.oonaysicilno = this.oonaysicilno;
                this.$store.commit('SetFields', payload);
            }
        },
        watch: {
        },
        filters: {
            FormatColumn: function (value, column, undefinedtext) {
                var result = value;
                if (result == '' || result == null) {
                    result = undefinedtext;
                }
                return result;
            }
        }
    }
</script>
