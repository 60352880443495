
import 'babel-polyfill';
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import VueMask from 'v-mask'
Vue.use(VueMask);


import App from './App.vue'
import router from './router'
import store from './store'

import { i18n, loadLanguageAsync } from '@/plugins/i18n'

import Vuetify from "vuetify"
import 'vuetify/dist/vuetify.min.css'
import showactionresult from '@/components/ShowActionResult.vue'
import showdialog from '@/components/ShowDialog.vue'

import '@mdi/font/css/materialdesignicons.css'
import "@/assets/style.css"


Vue.use(Vuetify);

const vuetifyOpts = {
    lang: {
        t: (key, ...params) => i18n.t(key, params)
    },
    
    theme: {
        dark: false, themes: {
            light: {
                primary: {
                    lighten5: '#3779b1',
                    lighten4: '#3779b1',
                    lighten3: '#3779b1',
                    lighten2: '#3779b1',
                    lighten1: '#3779b1',
                    base: '#3779b1',
                    darken1: '#3779b1',
                    darken2: '#3779b1',
                    darken3: '#3779b1',
                    darken4: '#3779b1'
                },
                secondary: {
                    lighten5: '#E8E8E8',
                    lighten4: '#C6C6C6',
                    lighten3: '#A1A1A1',
                    lighten2: '#7B7B7B',
                    lighten1: '#5E5E5E',
                    base: '#424242',
                    darken1: '#3C3C3C',
                    darken2: '#333333',
                    darken3: '#2B2B2B',
                    darken4: '#1D1D1D'
                },
                accent: {
                    lighten5: '#F5F5F6',
                    lighten4: '#E6E6E8',
                    lighten3: '#D5D5D9',
                    lighten2: '#C4C4CA',
                    lighten1: '#B8B7BE',
                    base: '#ABAAB3',
                    darken1: '#A4A3AC',
                    darken2: '#9A99A3',
                    darken3: '#91909A',
                    darken4: '#807F8B'
                },
                tertiary: {
                    lighten5: '#fef1e9',
                    lighten4: '#fde4d3',
                    lighten3: '#fbcaa7',
                    lighten2: '#fabd92',
                    lighten1: '#f8a366',
                    base: '#F7893B',
                    darken1: '#db7934',
                    darken2: '#c06a2d',
                    darken3: '#a45b27',
                    darken4: '#894c20'
                },
                success: '#28AD68',
                error: '#DA3951',
                info: '#2196F3',
                warning: '#F7893B',
                infoLight: '#50c3d1',
            },
        },
    }
}

Vue.config.productionTip = false
import Utilities from '@/Utilities.js'
import { FormMixIn } from "@/MixIns/FormMixins";
Vue.config.devtools = true;
new Vue({
    router,
    store,
    mixins: [FormMixIn],
    vuetify: new Vuetify(vuetifyOpts),
    i18n,
	methods:
	{
	//	UpdateTime() {
	//		Utilities.log("Update Time", this.$store.state.timeNow);
	//		this.$store.commit("UpdateTimeNow");
	//		setTimeout(() => { this.UpdateTime() }, 1000);
	//	}
        KeepAlive()
        {
            return;
			var req = {};

			req.payload = {};

			req.success = function (response) {
				Utilities.log("Alive:", response);
			};
			req.error = function (error) {
				Utilities.log("Dead:",error);
			};
            req.url = this.$store.getters.GetHostUrl + "api/PingBack"; // Runtime olarak tekrar yaz
			//this.$store.dispatch("FetchDataFromServer", req);
			setTimeout(this.KeepAlive ,600000)
        },
        loadLanguageAsync(lang) {
            loadLanguageAsync(lang);
        }
	},
    mounted: function ()
	{
		Utilities.log("MAIN MOUNTED")
		var hostUrl =
			window.location.protocol +
			'//' +
			window.location.hostname +
			(window.location.port ? ':' + window.location.port : '') +
			'/';
        this.$store.commit('SetHostUrl', hostUrl);
        var self = this;
        // /Account/ k�sm� login i�lemleri i�in. User bilgisi daha yok

        var lang = Utilities.GetCurrentLanguage();
        this.loadLanguageAsync(lang);

            this.$store.dispatch("GetUserInfo")
                .then(response =>
                {
                    if (self.$route.name != null && !this.IsAuthPage(self.$route.name)) {
                        Utilities.log("GetUserInfo Response:" + response.data.errorMessage);
                        if (response.data.errorCode == 0) {
                            if (response.data.result.Login == 0) {
                                self.ShowDialog(this.$root.$i18n.t('EM_LoginExpired'), 'E', 'notification');
                                this.$router.push({ name: 'login' });
                            }
                            else {
                                self.$store.commit("SetUserLoggedIn", response.data.result.Login == '1');
                                if (self.IsApprovedPage(this.$route.name) && !response.data.result.approved) {
                                    this.$router.push({ name: 'home' });
                                }
                            }
                        } else {
                            self.ShowDialog(response.data.errorMessage, 'E', 'notification');
                            this.$router.push({ name: 'login' });
                        }
                    }
                })
                .catch(err => {
                    console.log("GetUser Err:", err);
                    if (err.data != undefined) {
                        self.ShowDialog(err.data.errorMessage, 'E', 'notification');
                        if (err.data.errorCode == 401) {
                            this.$router.push({ name: 'login' });
                        }
                    }
                });
        		
    },
    render: h => h(App)
}).$mount('#app')

