<template>
    <v-dialog v-model="showMessage" width="390">
        <v-card class="mx-auto pt-7 pb-2">
            <alertIcon :icon="vicon" />
            <v-card-title class="mb-0">
                <p class="mx-auto headline pb-0 mb-0">{{ infoBody }}</p>
            </v-card-title>
            <v-card-text class="text-center" v-html="messageBody">
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :color="color" class="white--text font-weight-bolder px-5" @click="CloseDialog()">
                    {{$t("AL_Okey")}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import axios from 'axios';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import Utilities from '@/Utilities.js'

    import Vue from 'vue';
    import alertIcon from './alertIcon';
    Vue.component('alertIcon', alertIcon);

    export default {
        name: 'showactionresult',
        props: {
            message: Object
        },

        data()
        {
            return {
                messageType: '',
                messageBody: '',
                infoBody: '',
                vicon: 'error',
                color: 'red',
                messageHeader: '',
                showMessage: false,
                timerId: null,
                OnCloseDialog: undefined
            };
        },
        mixins: [FormMixIn],
        mounted()
        {
            this.$root.$on('setUpMessage', (data) =>
            {
                Utilities.log("setUpMessage ===> ", data);
                this.messageType = data.messageType;
                this.messageBody = data.messageBody;
                this.infoBody = data.infoBody;
                this.ChangeAlertDisplay(data.messageType);
                this.showMessage = true;
            });
        },
        methods: {
            CloseDialog()
            {
                Utilities.log('Close message dialog');
                if (this.messageType == 'I')
                {
                    // Yeniden view yükle

                    var viewname = this.GetViewName;
                    this.$store.commit('UpdateViewName', { viewName: '' });
                    setTimeout(() =>
                    {
                        Utilities.log(' Timeout UpdateViewName', viewname);
                        this.$store.commit('SetWhatToShow', { show: 'view' });
                        this.$store.commit('UpdateViewName', { viewName: viewname });
                    }, 1);
                }

                if (this.OnCloseDialog != undefined)
                {
                    this.OnCloseDialog();
                }

                this.showMessage = false;
                this.timerId = null;
            },
            ChangeAlertDisplay(type)
            {
                let _vicon = 'error';
                let _color = 'red';
                let _messageHeader = this.$root.$i18n.t('DM_Error');

                switch (type)
                {
                    case "B":
                        _vicon = 'success';
                        _color = 'green';
                        _messageHeader = this.$root.$i18n.t('DM_Success');
                        break;
                    case "I":
                        _vicon = 'info';
                        _color = 'blue';
                        _messageHeader = this.$root.$i18n.t('DM_Info');
                        break;
                    case "V":
                    case "W":
                        _vicon = 'warning';
                        _color = 'orange';
                        _messageHeader = this.$root.$i18n.t('DM_Warning');
                        break;
                    default:
                        _vicon = 'error';
                        _color = 'red';
                        _messageHeader = this.$root.$i18n.t('DM_Error');
                        break;
                }

                this.vicon = _vicon;
                this.color = _color;
                this.messageHeader = _messageHeader;
            }
        },
        watch: {

        }
    };
</script>
