<template>
    <div class="w-100 h-100">
        <div class="h-100 w-100">
            <input type="file"
                   ref="fileu"
                   v-on:change="handleFileUpload()"
                   style="position: absolute;top: -90000px"/>
            <v-text-field ref="filename" v-if="editable"
                          clearable
                          :placeholder="$t(params.label)"
                          :label="isInGrid ? '' : label"
                          :background-color="editable && !disabled ? 'white' : 'grey lighten-3'"
                          :disabled="disabled"
                          :error="error"
                          :class="{fielderror:error}" 
                          outlined
                          readonly
                          hide-details
                          :dense="isInGrid"
                          class="mb-2"
                          @click:clear="clearFile()"
                          :append-icon="approved == false ? 'mdi-timer-sand' : ''"
                          :value="filenamefield | FormatFileName($t(params.label))" />
            <v-btn v-if="editable && !disabled" depressed tile small @click="fileselect()" color="secondary" class="mr-2 white--text">
                <v-icon left small>attachment</v-icon>
                {{ params.newFileLabel != undefined ? $t(params.newFileLabel) : filenamefield == '' ? '' : $t("AL_New") + ' ' }}  {{  params.newFileLabel != undefined ? '' : $t("AL_SelectFile") }}
            </v-btn>
            <v-btn v-if="uploaded" depressed tile small @click="showFile()" color="secondary" class="mr-2 white--text">
                <v-icon left small>visibility</v-icon>
                {{ params.showFileLabel != undefined ? $t(params.showFileLabel) : $t("AL_View") }}
            </v-btn>
            <div v-else-if="!editable" class="body-2 font-weight-thin unspecified font-italic">
                {{ $t('ST_Undefined') }}
            </div>
            <v-btn v-if="params.draft" depressed tile small @click="showDraft()" color="secondary" class="white--text">
                <v-icon left small>mdi-file-document</v-icon>
                {{ params.draftLabel != undefined ? $t(params.draftLabel) : $t("AL_ShowDraft") }}
            </v-btn>
        </div>
    </div>
</template>
<style>
    .fielderror {
        border-color: red !important;
    }
</style>

<script>
    /*
    Eklenecek: Dosya seçilince Upload çıkacak, dosya boşsa 2. icon olmayacak, yüklenmiş dosya varsa göster iconu çıkacak
    1-) İnit: dosya varsa göster. Boşsa gösterme
    2-) dosya seçilince "göster" kalkacak, Yükle çıkacak
    3-) dosya yüklenince göster görünecek
    */
    import axios from "axios";
    import Utilities from '@/Utilities.js'

    import showdialog from '@/components/ShowDialog.vue';

    export default {
        name: "upload",
        data()
        {
            return {
                file: "",
                filenamefield: "",
                alertMessage: "",
                uploaded: false,
                initialValue: '',
            };
        },
        props: {
            params: Object,
            label: String,
            invalue: String,
            editable: Boolean,
            disabled: Boolean,
            error: Boolean,
            approved: Boolean,
            isInGrid: { type: Boolean, default: false }
        },
        mounted()
        {
            //Utilities.log("---upload Mounted invalue" + JSON.stringify(this.invalue));
            this.filenamefield = this.invalue || "";
            this.initialValue = this.filenamefield;
            this.uploaded = this.filenamefield != "" && this.filenamefield != undefined;
            this.file = this.filenamefield;
            var stat = this.uploaded ? 'FileUploaded' : 'NoFile'
            var payload = { attributeName: 'UploadStatus', value: stat };
            Utilities.log("Upload Emit changeAttribute", payload)
            this.$emit("changeAttribute", payload);
        },

        watch: {
            invalue: function (val, old)
            {
                //   Utilities.log(
                //     "File Watch:" + this.params.name + " Val:" + val + " Old:" + old
                //   );
                this.value = val;
                this.filenamefield = val;
                this.file = val;
                this.uploaded = this.filenamefield != "" && this.filenamefield != undefined;
                var stat = this.uploaded ? 'FileUploaded' : 'NoFile'
                var payload = { attributeName: 'UploadStatus', value: stat };
                Utilities.log("Upload Emit changeAttribute", payload)
                this.$emit("changeAttribute", payload);
            }
        },
        methods: {
            submitFile: function ()
            {
                Utilities.log("Upload");
                let formData = new FormData();
                console.log("File Size:", this.file.size)

                if (this.file.size > 20*1024*1024)
                {
                    var em = this.$root.$i18n.t("E_FileTooLarge");
                    var messages = {
                        errors: [em],
                        infoBody: '',
                        messageType: 'E',
                        viewType: 'notification',
                    };
                    this.$root.$emit('setUpDialog', messages);
                    this.clearFile();
                    return;
                } 
                formData.append("file", this.file);
                var self = this;
                axios
                    .post(
                        "/api/upload",
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        }
                    )
                    .then(function (response)
                    {
                        try
                        {
                            Utilities.log("SUCCESS File uploaded:", response);
                            let file = response.data.url;//  .data.files[0].url;
                            Utilities.log("File:" + file);
                            var error = response.data.error || "";
                            if (error != "")
                            {
                                var em = self.$root.$i18n.t(error);
                                var messages = {
                                    errors: [em],
                                    infoBody: '',
                                    messageType: 'E',
                                    viewType: 'notification',
                                };
                                self.$root.$emit('setUpDialog', messages);
                                self.clearFile();
                                return;
                            }
                            var payload = { fieldName: self.params.name, fieldValue: file };
                            self.$emit("change", payload);
                            self.file = file;
                            self.uploaded = true;
                            var payload = { attributeName: 'UploadStatus', value: 'FileUploaded' };
                            Utilities.log("Upload Emit changeAttribute", payload)
                            self.$emit("changeAttribute", payload);

                        } catch (e)
                        {
                            Utilities.log("file upload exception:" + e);
                        }
                    })
                    .catch(function ()
                    {
                        Utilities.log("FAILURE!!");
                    });
            },

            handleFileUpload: function ()
            {
                Utilities.log("clicked");
                this.file = this.$refs.fileu.files[0];
                Utilities.log("File Selected:" + JSON.stringify(this.file.name));
                this.filenamefield = this.file.name;
                var payload = { attributeName: 'UploadStatus', value: 'FileSelected' };
                Utilities.log("Upload Emit changeAttribute", payload)
                this.$emit("changeAttribute", payload);
                this.submitFile();
            },
            fileselect: function ()
            {
                this.uploaded = false;
                Utilities.log("this.filenamefield :" + this.filenamefield);
                if ((this.filenamefield || '') == "")
                {
                    this.$refs.fileu.click();
                    Utilities.log("File:" + JSON.stringify(this.$refs.fileu));
                } else
                {
                    this.filenamefield = "";
                    //var payload = { fieldName: this.params.name, fieldValue: '' };
                    //this.$emit("change", payload);
                    this.$refs.fileu.value = '';
                    var payload = { attributeName: 'UploadStatus', value: 'FileDeleted' };
                    Utilities.log("Upload Emit changeAttribute", payload)
                    this.$emit("changeAttribute", payload);
                    this.fileselect();
                }
            },
            clearFile: function ()
            {
                this.value = '';
                this.uploaded = false;
                this.filenamefield = "";
                this.$refs.fileu.value = '';
                var payload = { fieldName: this.params.name, fieldValue: '' };
                this.$emit("change", payload);
                this.file = '';
                var payload = { attributeName: 'UploadStatus', value: 'FileDeleted' };
                Utilities.log("Upload Emit changeAttribute", payload)
                this.$emit("changeAttribute", payload);
            },
            showFile: function ()
            {
                //alert("Show file:" + this.filenamefield);
                var url = "/api/showfile?file=" + this.file;
                window.open(url);
            },
            showDraft: function ()
            {
                //alert("Show file:" + this.filenamefield);
                var url = "/" + this.$root.$i18n.t(this.params.draft);
                window.open(url);
            },
            RollBack: function ()
            {
                this.filenamefield = this.initialValue;
                this.uploaded = this.filenamefield != "";
                //var payload = { fieldName: this.params.name, fieldValue: this.initialValue };
                //this.$emit("change", payload);
            }
        },
        filters: {
            FormatFileName: function (value, label)
            {
                var result = "";
                if (!value) return "";
                Utilities.log("format filename:" + value);
                var parts = value.split("__");
                if (parts.length >= 2)
                {
                    result = value.replace(parts[0] + "__", "");
                }
                else
                {
                    parts = value.split(".");
                    if (parts.length > 0)
                    {
                        result = label + "." + parts[parts.length - 1];
                    }
                    else
                    {
                        result = value;
                    }
                }
                return result;
            }
        },
        computed: {
            SelectIcon: function ()
            {
                var icon = "";
                if ((this.filenamefield || '') != "")
                {
                    icon = "refresh";
                } else
                {
                    icon = "attachment";
                }
                return icon;
            }
        }
    };
</script>
