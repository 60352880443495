<template>
    <div class="row" style="z-index:99; " >
        <div class="col-xs-10 " style="padding-left:15px;">
            <input type="text"
                   ref="price"
                   v-model="value"
                   @change="PriceChanged($event)"
                   class="number w-100 pr-1 text-right"
                   style="border:2px #808080 solid; border-radius:5px; width:100%; height:43px; vertical-align: middle; float:left;"/>
        </div>
        <div class="col-xs-2">
            <v-select 
                      outlined
                      menu-props="auto"
                      hide-details
                      :items="currencies"
                      v-model="currency"
                      item-text="text"
                      item-value="value"
                      :placeholder="$t('OP_PleaseSelect')"
                      @change="CurrencyChanged($event, params)"
                      color="primary">
            </v-select>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import dropdown from "./dropdown";
    Vue.component("dropdown", dropdown);
    import Utilities from '@/Utilities.js'

    export default {
        name: "money",

        data()
        {
            return {
                value: 0.00,
                currency: "",
                currencies: [{ text: "TL", value: 'TL' }, { text: "USD", value: 'USD' },
                    { text: "EUR", value: 'EUR' }, { text: "GBP", value: 'GBP' }],
            };
        },
        props: {
            params: Object,
            invalue: [String, Number],
            doviz: String
        },
        mounted()
        {
            Utilities.log(" ************** Money Mounted " + JSON.stringify(this.params) + " inval:" + this.invalue + " Doviz:" + this.doviz || "TL");
            this.value = this.invalue || 0;
            this.currency = this.doviz || "TL";
        },
        methods: {
            PriceChanged: function (event)
            {
                Utilities.log("price change:" + this.params)
                var val = this.value; // event.target.value;
                var fieldname = this.params.name;
                Utilities.log("val:" + val + " Params:" + JSON.stringify(this.params));
                var payload = { fieldName: fieldname, fieldValue: val };
                this.$emit("change", payload);
            },
            CurrencyChanged(e, params)
            {
                Utilities.log("val:" + val + " params:" + JSON.stringify(params));
                Utilities.log("val:" + val + " Params:" + JSON.stringify(e));
                var val = e.value;
                var fieldname = params.doviz;

                var payload = { fieldName: fieldname, fieldValue: val };
                this.$emit("change", payload);
            }
        },
        watch:
        {
            doviz: function ( newval, oldval)
            {
                //Utilities.log("watch doviz newval:" + newval);
                this.currency = newval;
            }
        }
    };
</script>
